import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Order, OrdersData } from './order'
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';


@Injectable()
export class OrdersService {

  private _orders: BehaviorSubject<OrdersData> =
    <BehaviorSubject<OrdersData>>new BehaviorSubject(new OrdersData);
  public readonly orders: Observable<OrdersData> = this._orders.asObservable();

  private withInfo(closure: (options: HttpHeaders) => void): void {
    let headers: any = {};
    let shopIdAttribute = this.auth.shops_list();
    if (shopIdAttribute) {
      headers.shop_id = shopIdAttribute;
    }

    let options = new HttpHeaders(headers)

    closure(options);
  }

  constructor(
    private _http: HttpClient,
    private auth: AuthService
  ) {
  }

  public load() {
    this.withInfo((options) => {
      this._http.get(environment.api.orders, { headers: options })
        .subscribe(
          (data: Order[]) => {
            this._orders.next({
              orders: data.map(order => {
                order.preconfirmation_date = order.preconfirmation_date || null;
                order.confirmation_date = order.confirmation_date || null;
                order.maker = order.maker || "AUTO";

                // backward compatibility
                if (order.price_code===undefined || order.price_code==null) {
                  if (order.comments!==undefined && order.comments!=null) {
                    let upComment = order.comments.toUpperCase();
                    if (upComment.includes("UNIF")) {
                      order.price_code = "UNIFORMIDAD";
                    } else if (upComment.includes("VIP")) {
                      order.price_code = "VIP";
                    } else if (upComment.includes("SIN CARGO")) {
                      order.price_code = "SIN_CARGO"
                    } else {
                      order.price_code = "NORMAL";
                    }
                  } else {
                    order.price_code = "NORMAL";
                  }
                }

                return order;
              }),
            });
          },
          (error) => {
            this._orders.next({
              orders: [],
            })
            console.log(error)
          }
        );
    })
  }

  public load2() {
    let all_data = [];
    const loadWithPagination = (options, LastEvaluatedKey=null) => {
      let url = environment.api.orders
      if (LastEvaluatedKey){
        url = url + "/?lastEvaluatedKey="+ LastEvaluatedKey
      }
      this._http.get(url, { headers: options }).subscribe(
        (data: any) => {
          all_data.push(...data["items_sorted"])

          if (typeof data.LastEvaluatedKey !== "undefined") {
            console.log(data.LastEvaluatedKey);
            // Continue loading with pagination by making another request
            loadWithPagination(options, data.LastEvaluatedKey['id']);
          } else {
            // No more LastEvaluatedKey, process the final data

            this._orders.next({
              orders: all_data.map(order => {
                order.preconfirmation_date = order.preconfirmation_date || null;
                order.confirmation_date = order.confirmation_date || null;
                order.maker = order.maker || "AUTO";

                // backward compatibility
                if (order.price_code===undefined || order.price_code==null) {
                  if (order.comments!==undefined && order.comments!=null) {
                    let upComment = order.comments.toUpperCase();
                    if (upComment.includes("UNIF")) {
                      order.price_code = "UNIFORMIDAD";
                    } else if (upComment.includes("VIP")) {
                      order.price_code = "VIP";
                    } else if (upComment.includes("SIN CARGO")) {
                      order.price_code = "SIN_CARGO"
                    } else {
                      order.price_code = "NORMAL";
                    }
                  } else {
                    order.price_code = "NORMAL";
                  }
                }

                return order;
              }),
            });
          }
        },
        (error) => {
          this._orders.next({
            orders: [],
          });
          console.log(error);
        }
      );
    };

    this.withInfo((options) => {
      loadWithPagination(options);
    });
  }

  public update(id: string, order: Order, callback?: (ok: boolean) => void) {
    this.withInfo((options) => {
      let url = environment.api.orders + "/" + id;
      this._http.put(url, order, { headers: options })
        .subscribe(
          (res: Response) => {
            if (callback) {
              callback(true);
            }
          },
          (error) => {
            console.log(error);
            callback(false);
          }
        )
    })
  }

}
