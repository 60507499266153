import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as $ from 'jquery';
import { AuthService } from '../auth/auth.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent {

  @Input() logo_img: string;
  private currentOption: string = 'home';
  private isMadeForYou: boolean

  constructor(
    private dialog: MatDialog,
    private auth: AuthService
  ) { }

  ngOnInit() {
    const url_to_read: string = window.location.href;
    this.isMadeForYou = url_to_read.includes("madeforyouconcept");
  }

  @Output() onNavigationEvent = new EventEmitter<string>();
  onNavigation($event) {
    let clickedElement = $event.target || $event.srcElement;
    this.onNavigationEvent.emit($(clickedElement).data('value'));
    this.currentOption = $(clickedElement).data('value')
  }
}