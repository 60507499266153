
export class ShippingAddress {
  name: string;
  address: string[];
}

export class Shop {
    id: string;
    catalogs;
    shippingAddress: ShippingAddress;
}

