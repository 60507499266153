

export default class Utils {
    static pushDownloadUrl(url: string, filename: string) {
        // The method downloads a file fiven its url. 
        // IMPORTANT: Not working on Internet Explorer. 
        var link = document.createElement("a");
        link.setAttribute('download', filename);
        // Construct the uri
        link.setAttribute('href', String(url));
        document.body.appendChild(link);
        window.requestAnimationFrame(function () {
            var event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });
    }

    static pushDownloadContent(content: string, filename: string) {
        // The method downloads a file fiven its url. 
        // IMPORTANT: Not working on Internet Explorer. 
        var link = document.createElement("a");
        link.setAttribute('download', filename);
        // Construct the uri
        var blob = new Blob([content], { type: 'text/plain' });
        let url = window.URL.createObjectURL(blob);


        link.setAttribute('href', String(url));
        document.body.appendChild(link);
        window.requestAnimationFrame(function () {
            var event = new MouseEvent('click');
            link.dispatchEvent(event);
            document.body.removeChild(link);
        });
    }
}