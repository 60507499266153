import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Fit } from '../services/fit';
import { FitsService } from '../services/fits.service';
import { HttpClient } from '@angular/common/http';
import Utils from '../utils/utils';
import * as $ from 'jquery';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { ThicknessService } from '../services/thickness.service';
import { bool } from 'aws-sdk/clients/signer';
import { MeasuresService } from '../services/measures.service';

@Component({
    selector: 'app-fit-details',
    templateUrl: './fit_details.component.html',
    styleUrls: ['./fit_details.component.css']
})
export class FitDetailsComponent {
    @Input()
    public fit: Fit;
    private isOpeningThickness: bool = false
    private isOpeningFit: bool = false

    constructor(
      private _fitsService: FitsService,
      private http: HttpClient,
      private auth: AuthService,
      private thickness_service: ThicknessService,
      private measuresService: MeasuresService
    ) { }

    @ViewChild('img') img: ElementRef;
    ngAfterViewInit() {
      if (!this.fit.img) {
        this.http.get(this.img.nativeElement.getAttribute('data-src'), {responseType: 'text'})
        .subscribe(
          (info) => {
            if (info == 'image not found') {
              this.img.nativeElement.setAttribute('src', '/assets/no_img_avaliable.jpg')
            } else {
              this.img.nativeElement.setAttribute('src', info)
              this.fit.img = info
            }
          },
          _ => {
            this.img.nativeElement.setAttribute('src', '/assets/no_img_avaliable.jpg')
          }
        );
      } else {
        this.img.nativeElement.setAttribute('src', this.fit.img)
      }
    }

    // called from html
    private onSaveComments() {
      let body:any ={};
      body.tag_id = this.fit.TagID
      body.comments = this.fit.comments
      this._fitsService.update(body, (ok) => {
        let selector: string = `#details-${this.fit.TagID} #inputComments`;
        let className: string = (ok ? 'save-result-success' : 'save-result-error');
        $(selector).addClass(className);
        setTimeout(() => {
          $(selector).removeClass(className);
        }, 750);
      });
    }

    // called from html
    private pushContent(content_in: string): void {
      let filename = this.fit.code_measure;
      console.log(this.fit.code_measure)
      let fn = null;

      filename += '.vca'
      let content = ""
      content += "IPD=" + this.fit.measures.Naso_R + ";" + this.fit.measures.Naso_L + "\n"
      content += "SEGHT=" + this.fit.measures.HeightBOX_R + ";" + this.fit.measures.HeightBOX_L + "\n"
      if (this.fit.measures.HeightFrame_R!==undefined){
        content += "SEGDHT=" + this.fit.measures.HeightFrame_R + ";" + this.fit.measures.HeightFrame_L + "\n"
      }
      content += "BVD=" + this.fit.measures.BVD_R + ";" + this.fit.measures.BVD_L + "\n"
      content += "PANTO=" + this.fit.measures.Panto + "\n"
      content += "ZTILT=" + this.fit.measures.Tilt + "\n"
      content += "HBOX=" + this.fit.measures.HBOX + "\n"
      content += "VBOX=" + this.fit.measures.VBOX + "\n"
      content += "DBL=" + this.fit.measures.DBL + "\n"


      let resp = content
      Utils.pushDownloadContent(resp, filename)

  }

  private showSpinnerOnDownload(show: boolean) {
    let ctrl = $('#download-spinner');
    if (show) {
      ctrl.removeClass('d-none');
    } else {
      ctrl.addClass('d-none');
    }

  }

  private pushContentTar(): void {
    this.showSpinnerOnDownload(true);
    let filename = this.fit.TagID + '.tar.gz';

      this.http
        .get<any>(`${environment.api.fits}/${this.fit.TagID}?redirect=false`)
        .subscribe(
          resp => {
            //if tar
            if(resp.location != "Empty") {
              Utils.pushDownloadUrl(String(resp.location), filename)
              this.showSpinnerOnDownload(false);
            }
            //if metadata
            else {
              //download as METADATA
              this.http
              .get<any>(`${environment.api.metadata_tar_api}/${this.fit.TagID}?redirect=false`)
              .subscribe(
                resp2 => {
                  if(resp2.location) {
                    Utils.pushDownloadUrl(String(resp2.location), filename);
                    this.showSpinnerOnDownload(false)
                  }
                  else {
                    console.log(resp2)
                    this.showSpinnerOnDownload(false)
                    alert(`It\'s not possible to download this measure: ${this.fit.code_measure}`)
                  }
                },
                err2 => {
                  console.log(err2)
                  this.showSpinnerOnDownload(false)
                  alert(`It\'s not possible to download this measure: ${this.fit.code_measure}`)
                }
              )
            }
          },
          err => {
            console.log(err)
            this.showSpinnerOnDownload(false);
            alert(`It\'s not possible to download this measure: ${this.fit.code_measure}`)
          }
        ); 
  }

  private showSpinnerOnThickness(show: boolean) {
    if (show) {
      this.isOpeningThickness = true
    } else {
      this.isOpeningThickness = false
    }
  }

  private showSpinnerOnFit(show: boolean) {
    if (show) {
      this.isOpeningFit = true
    } else {
      this.isOpeningFit = false
    }
  }

  private goToThickness(tag_id: string): void {
    this.showSpinnerOnThickness(true)
    this.thickness_service.goToThickness(tag_id).then(
      () =>{
        this.showSpinnerOnThickness(false)
      }
      )
  };

  private isFitFinished() {
    return this.fit.finished != undefined ? this.fit.finished : true
  }

  private goToFit(tag_id: string, show_pupils=false): void {
    this.showSpinnerOnFit(true);
    this._fitsService.goToFit(tag_id, show_pupils).then(
      () =>{
        this.showSpinnerOnFit(false);
        document.getElementById("home-button").click();
      }
      ).catch(
        error => {
          this.showSpinnerOnFit(false);
          console.log(error);
          alert(`The measure ${this.fit.code_measure} is not available for processing.`);
        }
      )
  };

  private downloadPDF(): void {
    this.measuresService.generatePDF(
      this.fit.measures,
      this.fit.code_measure,
      this.fit.internalRef,
      this.fit.shop_id,
      this.fit.img
      )
  }
}