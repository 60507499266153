import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../environments/environment';


@Component({
    selector: 'app-sizeselector',
    template: `
        <select class="custom-select mb-3 select-horizons" (change)="onChange($event)">
            <option *ngFor="let talla of tallas" [selected]="talla.name == defaultSize">{{ talla.name }}</option>
        </select>
    `
})
export class SizeselectorComponent {

    private catalog: string = null;
    private frame: string = null;
    private tallas: any[] = [];
    private defaultSize: string = null;
    private client: S3 = new S3(environment.catalogaccess);
    @Output() onSelectionT = new EventEmitter<string>();

    public load(catalog: string, frame: string, def: string = null) {
        
        this.tallas = []; 
        this.talla = null;
        this.defaultSize = def;
        
        if (catalog && frame) {

            this.client.listObjects({
                Bucket: environment.catalogaccess.bucketName,
                Prefix: catalog + '/' + frame + '/'
            }, (err, data) => {

                var FOLDER_PATTERN = new RegExp('_\\' + catalog + '/' + frame + '/\\$$');

                function Talla(key) {
                    var self = this;
                    self.key = key;
                    // init logic
                    self.isFolder = FOLDER_PATTERN.test(self.key);
                    self.filename = self.isFolder ? self.key.replace(FOLDER_PATTERN, '') : self.key;
                    self.levels = self.filename.split('/');
                    self.numLevels = self.levels.length;
                    self.simpleFilename = self.levels[self.numLevels - 1];

                    self.name = self.simpleFilename.replace('.xml', '')
                }

                data.Contents.forEach((element) => {
                    if (element.Key.endsWith('.xml')) {
                        this.tallas.push(new Talla(element.Key));
                    }
                });

                if(this.defaultSize){
                    this.talla = this.defaultSize;
                }
                else{
                    this.talla = this.tallas[0].name;
                }
            });
        }
    }


    public talla: string = null;
    private onChange(event) {
        let selection = event.target || event.srcElement;
        this.talla = selection.options[selection.selectedIndex].value;
        var res = findTallaPath(this.talla, this.tallas)
        this.onSelectionT.emit( res);
    }    
}

function findTallaPath(size_str, tallas){
    for(var num_talla=0; num_talla<tallas.length; num_talla++ ){
        var val_talla = String(tallas[num_talla].key )
        if(val_talla.includes(size_str))
            {
                return val_talla  
            }
    }
}