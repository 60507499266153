class measures {
    HBOX: number;
    Naso_R: number;
    Naso_L: number;
    BVD_L: number;
    BVD_R: number;
    HeightBOX_R: number;
    HeightBOX_L: number;
    HeightFrame_R: number;
    HeightFrame_L: number;
    DBL: number;
    Panto: number;
    VBOX: number;
    Tilt: number;
    NearPD_R: number;
    NearPD_L: number;
    Inset_R: number;
    Inset_L: number;
    Corridor: number;
    NearDistance: number;
}


export class Fit {
    TagID: string;
    code_measure: string;
    shop_id: string;
    date: Date;
    local_date: Date;
    //date_tar: Date;
    internalRef: string;
    internalRef_block: any;
    comments: string;
    measures: measures;
    tag_img: any;
    img: any = null;
    finished: boolean;
}

export class FitsData {
    fits: Fit[] = [];
}