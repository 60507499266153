import { 
    Component, Injector,
    ComponentFactoryResolver, ApplicationRef, EmbeddedViewRef,
    Renderer2, ElementRef,
  } from '@angular/core';
  import { ShopService } from '../services/shop.service';
  import { Shop } from '../services/shop';
  
  
  @Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
  })
  export class SettingsComponent {
    constructor(
      private componentFactoryResolver: ComponentFactoryResolver,
      private injector: Injector,
      private appRef: ApplicationRef,
      private renderer: Renderer2,
      private me: ElementRef,
      private shopService: ShopService,
    ) { }

  }