import { Injectable, Injector, InjectionToken } from '@angular/core';
import { 
    HttpClient, 
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpBackend,
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, flatMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';


export const provideTokenizedHttpClient = (token: InjectionToken<string>, options: { excludes: Function[] } = { excludes: [] }) => {
    return {
        provide: token,
        deps: [HttpBackend, Injector],
        useFactory: (backend: HttpBackend, injector: Injector) => {
            return new HttpClient(
                new HttpDynamicInterceptingHandler(backend, injector, options)
            );
        }
    }
}

class HttpInterceptorHandler implements HttpHandler {
    constructor(private next: HttpHandler, private interceptor: HttpInterceptor) { }
    handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this.interceptor.intercept(req, this.next);
    }
}

class HttpDynamicInterceptingHandler implements HttpHandler {
    private chain: any = null;

    constructor(private backend: HttpBackend, private injector: Injector, private options: { excludes: Function[] } = { excludes: [] }) { }

    public handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        if (this.chain === null) {
            const interceptors = this.injector.get(HTTP_INTERCEPTORS, [])
                .filter(entry => !this.options.excludes.includes(entry.constructor));

            this.chain = interceptors.reduceRight((next, interceptor) => {
                return new HttpInterceptorHandler(next, interceptor);
            }, this.backend);
        }
        return this.chain.handle(req);
    }
}

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService) {}

    private addToken(req: HttpRequest<any>) : HttpRequest<any> {
        let token = this.auth.access_token();
        if (!token) {
            throwError('no auth token!');
        }

        return req.clone({ 
            headers: req.headers
                .set('Authorization', token) 
        });
    }

    private addBadToken(req: HttpRequest<any>) : HttpRequest<any> {
        let token = this.auth.access_bad_token();
        if (!token) {
            throwError('no auth token!');
        }

        return req.clone({ 
            headers: req.headers
                .set('Authorization', token) 
        });
    }


    public intercept(
        req: HttpRequest<any>, 
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        return next.handle(this.addToken(req)).pipe(catchError( err => {
            if (err instanceof HttpErrorResponse && err.status === 403) {
                return this.auth.refresh().pipe(
                    first(),
                    flatMap((value) => {
                        if (value) {
                            return next.handle(this.addToken(req));
                        } else {
                            return throwError(err);
                        }
                    })
                );
            } else {
                return throwError(err);
            }
        }));
    }

}
