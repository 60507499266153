import { 
  Component, Injector,
  ComponentFactoryResolver, ApplicationRef, EmbeddedViewRef,
  Renderer2, ElementRef,
} from '@angular/core';
import { Order } from '../services/order';
import { DeliverynoteComponent } from './deliverynote.component';
import { ShopService } from '../services/shop.service';
import { Shop } from '../services/shop';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private renderer: Renderer2,
    private me: ElementRef,
    private shopService: ShopService,
  ) { }

  private printDeliveryNote(order: Order) {
    let printingDiv = this.renderer.createElement('div');
    this.renderer.addClass(printingDiv, 'd-none');
    this.renderer.addClass(printingDiv, 'd-print-block');

    this.shopService.get(order.shop_id)
    .subscribe((shop: Shop) => {
      const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(DeliverynoteComponent)
      .create(this.injector);

      componentRef.instance.order = order;
      componentRef.instance.shop = shop;

      // Attach component to the appRef so that it's inside the ng component tree
      this.appRef.attachView(componentRef.hostView);

      // Get DOM element from component
      const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;

      this.renderer.appendChild(printingDiv, domElem);

      // clean up when closing
      componentRef.instance.onPrintingEnds.subscribe(() => {
        this.renderer.removeChild(this.me.nativeElement, printingDiv);
      });

      this.renderer.appendChild(this.me.nativeElement, printingDiv);
    });
  }
}
