import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Fit, FitsData } from './fit'
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class Workaround {

  private round(value: any, precision: any) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  private avg(value1: any, value2: any) {
    return (value1 + value2) / 2.0;
  }

  private reduce_assimetry_naso(right_val, left_val){
    let M = this.avg(right_val, left_val);
    const A = 0.3;
    const D = (A * (M - right_val));
    right_val = this.round(M - D, 1);
    left_val = this.round(M + D, 1);
    return [right_val, left_val]
  }

  private reduce_assimetry_height(right_val, left_val){
    let M = this.avg(right_val, left_val);
    const A = 0.5;
    const D = (A * (M - right_val));
    right_val = this.round(M - D, 1);
    left_val = this.round(M + D, 1);
    return [right_val, left_val]
  }

  private back_vertex_distance(fits:any, workaround_version:any) {
    let M = this.avg(fits.measures.BVD_R, fits.measures.BVD_L);
    const A = 0.3;
    const D = (A * (M - fits.measures.BVD_R));
    fits.measures.BVD_R = this.round(M - D - 4.0, 1);
    fits.measures.BVD_L = this.round(M + D - 4.0, 1);
    if (workaround_version >= 2){
      fits.measures.BVD_R = this.round(fits.measures.BVD_R - ((fits.measures.BVD_R - 12) * 0.3),1)
      fits.measures.BVD_L = this.round(fits.measures.BVD_L - ((fits.measures.BVD_L - 12) * 0.3),1);
    }
    return fits;
  }

  private interpupillary_distance(fits: any, workaround_version:any) {

    if ("IPD_noframe" in fits.measures && workaround_version  >= 4){
      let oldNaso = fits.measures.Naso_R + fits.measures.Naso_L
      let correctionParam = fits.measures['IPD_noframe']/oldNaso
      fits.measures.Naso_R = fits.measures.Naso_R*correctionParam
      fits.measures.Naso_L = fits.measures.Naso_L*correctionParam
    }

    let nasos = this.reduce_assimetry_naso(fits.measures.Naso_R, fits.measures.Naso_L)
    fits.measures.Naso_R = nasos[0];
    fits.measures.Naso_L = nasos[1];
    return fits;
  }

  public apply_workaround(fit: any){
    let workaround_version = 1
    let heightFrame;
    if ("workaround_version" in fit){
      workaround_version = fit["workaround_version"]
    }
    fit = this.back_vertex_distance(fit, workaround_version);
    fit = this.interpupillary_distance(fit, workaround_version);
    if ("HeightFrame_R" in fit.measures){
      fit.measures.HeightFrame_R = (fit.measures.HeightFrame_R > fit.measures.HeightBOX_R) ?
      fit.measures.HeightBOX_R : fit.measures.HeightFrame_R;

      fit.measures.HeightFrame_L = (fit.measures.HeightFrame_L > fit.measures.HeightBOX_L) ?
      fit.measures.HeightBOX_L : fit.measures.HeightFrame_L;
      
      if (workaround_version == 2){
        heightFrame = this.reduce_assimetry_naso(fit.measures.HeightFrame_R, fit.measures.HeightFrame_L)
        fit.measures.HeightFrame_R = heightFrame[0]
        fit.measures.HeightFrame_L = heightFrame[1]
      }else if(workaround_version >= 3){
        heightFrame = this.reduce_assimetry_height(fit.measures.HeightFrame_R, fit.measures.HeightFrame_L)
        fit.measures.HeightFrame_R = heightFrame[0]
        fit.measures.HeightFrame_L = heightFrame[1]
      }
    }
    if (workaround_version == 2){
      heightFrame = this.reduce_assimetry_naso(fit.measures.HeightBOX_R, fit.measures.HeightBOX_L)
      fit.measures.HeightBOX_R = heightFrame[0]
      fit.measures.HeightBOX_L = heightFrame[1]
    }else if(workaround_version >= 3){
      heightFrame = this.reduce_assimetry_height(fit.measures.HeightBOX_R, fit.measures.HeightBOX_L)
      fit.measures.HeightBOX_R = heightFrame[0]
      fit.measures.HeightBOX_L = heightFrame[1]
    }
    return fit

  }

}

@Injectable()
export class FitsService {

  private _fits: BehaviorSubject<FitsData> =
    <BehaviorSubject<FitsData>>new BehaviorSubject(new FitsData);
  public readonly fits: Observable<FitsData> = this._fits.asObservable();

  private withInfo(closure: (options: HttpHeaders) => void): void {
    let options = new HttpHeaders();
    options = options.set('Content-Type', 'application/json; charset=utf-8');

    closure(options);
  }

  constructor(
    private _http: HttpClient,
    private auth: AuthService,
    private workAround: Workaround,
  ) {
  }

  public load() {
    this.withInfo((options) => {
      let shop_id = this.auth.shops_list();
      var self = this;

      let body = JSON.stringify({
        shop_id: shop_id,
        });
      this._http.post(
        environment.api.fits + "/list" ,
          body,
          { headers: options },
      ).subscribe(
          (data: Fit[]) => {
            var tag_list = data["tag_list"]
            tag_list.forEach((fit: Fit)=>{
              if(fit.hasOwnProperty("measures")){
                for (const [key, value] of Object.entries(fit.measures)) {
                  fit["measures"][key] = parseFloat(value)
                }
                self.workAround.apply_workaround(fit)
              }
              fit["tag_img"] = environment.api.fits + "/img/" + fit["code_measure"];
            })
            this._fits.next({
              fits: tag_list,
            });
          },
          (error) => {
            this._fits.next({
              fits: [],
            })
            console.log(error)
          }
        );
    })
  }

  public load_error() {
    this.withInfo((options) => {
      let shop_id	= this.auth.shops_list();
      var self = this;

      let body = JSON.stringify({
        shop_id: shop_id,
        });
      this._http.post(
        environment.api.fits + "/list_error" ,
          body,
          { headers: options },
      ).subscribe(
          (data: Fit[]) => {
            var tag_list = data["tag_list"]
            tag_list.forEach((fit: Fit)=>{
              fit["tag_img"] = environment.api.fits + "/img/" + fit["code_measure"];
            })
            this._fits.next({
              fits: tag_list,
            });
          },
          (error) => {
            this._fits.next({
              fits: [],
            })
            console.log(error)
          }
        );
    })
  }

  public update(body: any, callback?: (ok: boolean) => void) {
    this.withInfo((options) => {
      this._http.post(
          environment.api.fits + "/update" ,
          body,
        { headers: options }
      ).subscribe(
          (res: Response) => {
            if (callback) {
              callback(res.ok);
            }
          },
          error => console.log(error)
      )
    })
  }

  public goToFit(tag_id: string, show_pupils=false): Promise<String> {

    let url_fitid = environment.api.measures.concat(tag_id.toString())
    let options_check_folder = new HttpHeaders();
    return new Promise((resolve, reject) => {
      this._http.get(environment.api.check_folder + `/${tag_id}`, {headers: options_check_folder})
      .subscribe(
        () => {
          this.withInfo((options) => {
            options = options.set('Content-Type', 'application/json');
            this._http.get(
              url_fitid,
              { headers: options }
            ).subscribe(
              () => {
                options = options.set('Access-Control-Allow-Credentials', 'true');
                this._http.get(
                  environment.api.fit_api.concat("login"),
                  { headers: options, withCredentials: true }
                ).subscribe(
                  () => {
                    window.open(
                      environment.api.finish_fit
                      .concat("?fitid=")
                      .concat(tag_id.toString())
                      .concat(`${show_pupils ? "&pupils=1" : ""}`),
                      "_blank").focus();
                    resolve("done")
                  },
                  (error) => {
                    console.log("Error on fit login")
                    console.log(error)
                  }
                )
              },
              (error) => {
                console.log(error);
                console.log("Error on fit login");
              }
            );
          })
        },
        err => {
          reject(err);
        });
  })
  }
}