import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService, Auth } from '../../auth/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  constructor(
    private auth: AuthService
  ) { }

  private showSpinnerOnLogin(show: boolean) {
    let ctrl = $('#login-spinner');
    if (show) {
      ctrl.removeClass('d-none');
    } else {
      ctrl.addClass('d-none');
    }

  }


  ngOnInit() {
    this.auth.onAuthChanged
    .subscribe((authState: Auth) => {
      this.showSpinnerOnLogin(false);
    });
  }

  ngAfterViewInit() {
    this.showSpinnerOnLogin(true);
    this.auth.init();
  }

  private formUser: string;
  private formPasswd: string;

  onLogin() {
    this.showSpinnerOnLogin(true);
    this.auth.login(this.formUser, this.formPasswd);
  }

  onLogout() {
    this.auth.logout();
  }
}
