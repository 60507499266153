import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogselectorComponent } from './catalogselector.component';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CatalogselectorComponent,
  ],
  exports: [
    CatalogselectorComponent,
  ],
})
export class CatalogControlsModule { }
