import {
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import * as $ from 'jquery';


@Component({
  selector: 'app-deliverynoteconfirmation',
  templateUrl: './deliverynoteconfirmation.component.html',
  styleUrls: ['./deliverynoteconfirmation.component.css']
})
export class DeliverynoteconfirmationComponent {
  id: string;
  date: string;

  constructor(
    private me: BsModalRef,
    private modalService: BsModalService,
  ) {
  };

  @Output() accept = new EventEmitter();
  ok(): void {

    // emit event to show print preview just after
    // modal is hidden to avoid 'bluring' on printing
    // comming from modal still alive
    let onHiddenSubscription: Subscription =
      this.modalService.onHidden.subscribe((reason: string) => {
        this.accept.emit(moment(new Date(this.date)));
        onHiddenSubscription.unsubscribe();
      });

    // hide modal and wait for event subscribed just above
    this.me.hide();
  }
}
