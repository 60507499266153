import { Injectable, Inject, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HTTP_NOAUTH } from './httpclient.def';


export class Auth {
    username: string = "";
    attributes: any = {};
    id_token: string = "";
    access_token: string = "";
    refresh_token: string = "";
    message: string = "";
    expiration: number = 3600; // 1h = 3600s
}

@Injectable()
export class AuthService {
    
    constructor(@Inject(HTTP_NOAUTH) private http: HttpClient) { }

    private currentSession: Auth = null;
    @Output() onAuthChanged = new EventEmitter<Auth>();

    private set(auth: Auth) {
        this.currentSession=auth;
        this.onAuthChanged.emit(auth ? auth : null);
    }

    private id_token(): string {
        return this.currentSession ? this.currentSession.id_token : null;
    }

    public access_token(): string {
        return this.currentSession ? this.currentSession.access_token : null;
    }

    /* for dev purpose */
    public access_bad_token(): string {
        if (this.currentSession) {
            this.currentSession.access_token += 'a';
        }
        return this.access_token();
    }

    public refresh_token(): string {
        return this.currentSession ? this.currentSession.refresh_token : null;
    }

    /* attributes */
    public isadmin(): boolean {
        if (this.currentSession) {
            if (this.currentSession.attributes.admin) {
                return this.currentSession.attributes.admin;
            }
            else return false;
        } else return false;
    }

    public hasFits(): boolean {
        if (this.currentSession) {
            if (this.currentSession.attributes.fits) {
                return this.currentSession.attributes.fits;
            }
            else return false;
        } else return false;
    }

    public hasOrders(): boolean {
        if (this.currentSession) {
            if (this.currentSession.attributes.orders) {
                return this.currentSession.attributes.orders;
            }
            else return false;
        } else return false;
    }

    public ismultishop(): boolean {
        if (this.currentSession) {
            if (this.currentSession.attributes.multishop) {
                return this.currentSession.attributes.multishop;
            }
            else return false;
        } else return false;
    }

    public username(): string {
        return this.currentSession ? this.currentSession.username : null;
    }

    public shop_id(): string {
        return (this.currentSession && this.currentSession.attributes.shop_id) ?
            this.currentSession.attributes.shop_id :
            null;
    }

    public pricelist_admin(): boolean {
        return (this.currentSession && this.currentSession.attributes.pricelist_admin) ?
            this.currentSession.attributes.pricelist_admin :
            false;
    }

    public shops_list(): string {
        if (!this.currentSession)
            return null;

        let value: string = '';
        if (this.shop_id()) {
            value = this.shop_id();
            if (this.currentSession.attributes.partners) {
                value += '+' + this.currentSession.attributes.partners;
            }
        } else if (this.currentSession.attributes.partners) {
            value = this.currentSession.attributes.partners;
        }

        return value;
    }

    public is_fits(): boolean {
        if (this.currentSession) {
            if (this.currentSession.attributes.product_id) {
                return this.currentSession.attributes.product_id == "CENTRADOR";
            }
            else return false;
        } else return false;        
    }

    /* end attributes */

    private static save(auth: Auth) {
        localStorage.setItem("auth", JSON.stringify({
            auth: auth,
            timestamp: new Date().getTime()
        }));
    }

    private static clean() {
        localStorage.removeItem("auth");
    }
    

    public init() {
        let payload = localStorage.getItem("auth");
        let rawAuth = JSON.parse(payload);

        if ( !rawAuth ||
             !rawAuth.timestamp || 
             !rawAuth.auth || 
             !rawAuth.auth.id_token ||
             !rawAuth.auth.access_token ) 
        {
            AuthService.clean();
            this.set(null);
            return;
        }

        let tspan = new Date().getTime() - rawAuth.timestamp;
        if (tspan > (rawAuth.auth.expiration*1000) )
        {
            AuthService.clean();
            this.set(null);
            return;
        }


        this.instrospection(rawAuth.auth.access_token)
        .subscribe(
            (response: any) => { 
                if (response.active) {
                    this.set(rawAuth.auth);
                } else {
                    this.set(null);
                }
            },
            error => {
                console.log(error)
                this.set(null);
            }
        );

    }

    public getProducts(shop_id: string) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.api.crm + "/shop_products/" + shop_id)
            .subscribe(
              (value: any) => {
                let dataProducts = [value.fit, value.scanner]
                resolve(dataProducts)
              },
              (error: any) => {
                resolve(false)
              }
            )
          });
    }

    public login(user: string, password: string) {
        let payload = JSON.stringify({
            user: user,
            password: password
        });

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': environment.auth.key,
            })
        };

        this.http.post<Auth>(
            environment.auth.endpoint + "/login",
            payload, httpOptions
        ).subscribe(
            (rawAuth: any) => { 
                let auth = new Auth();
                auth.username=rawAuth.username;
                auth.id_token=rawAuth.id_token;
                auth.access_token=rawAuth.access_token;
                auth.refresh_token = rawAuth.refresh_token;
                auth.attributes=rawAuth.attributes;
                this.getProducts(rawAuth.username).then( (data) => {
                    auth.attributes.fits =  Boolean(Number(data[0]));
                    auth.attributes.orders = Boolean(Number(data[1]));
                    AuthService.save(auth);
                    this.set(auth);
                });
            },
            error => {
                console.log(error)
                AuthService.clean();
                let auth = new Auth;
                auth.message = "invalid user or password"
                this.set(auth);
            }
        )
    }

    public refresh() : Observable<boolean> {

        return new Observable<boolean>(observer => {
            let payload=JSON.stringify({
                refresh_token: this.refresh_token()
            });
    
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': environment.auth.key,
                })
            };
    
            this.http.post<Auth>(
                environment.auth.endpoint + "/login",
                payload, httpOptions
            ).subscribe(
                (rawAuth: any) => { 
                    let auth = new Auth();

                    if (rawAuth.success) {
                        auth.username=rawAuth.username;
                        auth.id_token=rawAuth.id_token;
                        auth.access_token=rawAuth.access_token;
                        auth.refresh_token = rawAuth.refresh_token;
                        auth.attributes=rawAuth.attributes;
        
                        AuthService.save(auth);
                        this.set(auth);
        
                        observer.next(true);
                    } else {
                        AuthService.clean();
                        let auth = new Auth;
                        auth.message = "invalid refresh token"
                        this.set(auth);

                        observer.next(false);
                    }
                },
                error => {
                    console.log(error)
                    AuthService.clean();
                    let auth = new Auth;
                    auth.message = "invalid user or password"
                    this.set(auth);
    
                    observer.next(false);
                }
            );
        });

    }

    public logout() {
        AuthService.clean();
        this.set(null);
    }

    private instrospection(token: string) {
        let body = new URLSearchParams();
        body.set('token', token);

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': environment.auth.key,
            })
        };

        return this.http.post<Auth>(
            environment.auth.endpoint + "/introspection",
            body.toString(), httpOptions
        )
    }
}