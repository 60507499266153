import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Order } from '../services/order';
import { OrdersService } from '../services/orders.service';
import { Title } from '@angular/platform-browser';
import { Shop } from '../services/shop';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as moment from 'moment';


@Component({
  selector: 'app-deliverynote',
  templateUrl: './deliverynote.component.html',
  styleUrls: ['./deliverynote.component.css']
})
export class DeliverynoteComponent implements OnInit {
  @Input()
  public order: Order;
  public shop: Shop;
  private imageLoaded: BehaviorSubject<boolean> = 
    <BehaviorSubject<boolean>>new BehaviorSubject(false);
  private img1_loaded: boolean;
  private img2_loaded: boolean;

  constructor(
    private _titleService: Title,
    private _ordersService: OrdersService,
  ) {
  }

  @Output() onPrintingEnds = new EventEmitter<void>();

  ngOnInit() {

    // set delivery date if not yet there
    if (!this.order.deliveryDate) {
      this.order.deliveryDate = new Date();
    }

    this._ordersService.update(this.order.id, {
      deliveryDate: this.order.deliveryDate
    } as Order);    

    // set window title to force default printing pdf filename
    let previousTitle = this._titleService.getTitle();
    this._titleService.setTitle(
      moment(this.order.deliveryDate).format('YYYYMMDD') + '_' +
      this.order.shop_id + '_' +'D' + this.order.id
    );

    this.imageLoaded.subscribe(ready => {
      if (ready) {
        window.print();

        // revert window title when finish
        this._titleService.setTitle(previousTitle);

        this.onPrintingEnds.emit();
      }
    });
  }
  private needsVisilabCert(){
     return this.order.shop_id.includes("VISILAB");
  }

  // wait until logo is loaded to call print
  private onLogoLoaded() {
    this.img1_loaded = true
    let ready = null;
    if(this.needsVisilabCert()){
      ready = this.img1_loaded && this.img2_loaded
    }else{
      ready = this.img1_loaded
    }
    this.imageLoaded.next(ready);
  }

  private onLogoLoaded2() {
    this.img2_loaded = true
    let ready = this.img1_loaded && this.img2_loaded
    this.imageLoaded.next(ready);
  }
}
