import {  Component,
  Injector,
  ComponentFactoryResolver, ApplicationRef, EmbeddedViewRef,
  OnInit, EventEmitter,
  } from '@angular/core';
import 'datatables.net'
import { ShopCatalogsComponent} from './shopcatalogs.component';
import { Shop } from '../services/shop';
import {ShopService} from '../services/shop.service'

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['../orders/table.component.css']
})
export class ShopsComponent implements OnInit { 
    public shops: Shop[] = [];
    constructor(
      private shopService: ShopService,
      private componentFactoryResolver: ComponentFactoryResolver,
      private injector: Injector,
      private appRef: ApplicationRef,

    ) { }

  private _table: DataTables.Api = null;   
  private dataLoaded = new EventEmitter<void>();
  ngOnInit() {
    this.shopService.load()
    .subscribe(
      (shops: Shop[]) => {
        try{
          this.shops  = shops;
          this.dataLoaded.emit();
        }
        catch{
        }
      }
    )
  }

  ngAfterViewInit() {
    this.dataLoaded.subscribe(() => {
      setTimeout(() => {
        this._table = $('#catalogsTable').DataTable({
          destroy: true,
          order: [[1, 'asc']],
        });
      }, 50);
    });
  }

  private onRowSelect(shop: Shop) {
    let row = this._table.row('#row-' + shop['id']);
    if (row.child.isShown()) {
      row.child(false);
      row.$('#row-' + shop['id']).removeClass('shown');
    } else {
      /*console.log(shop['id'] )
      row.child(shop['id']).show();*/

      const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(ShopCatalogsComponent)
      .create(this.injector);

      componentRef.instance.shop = shop;
    
      // Attach component to the appRef so that it's inside the ng component tree
      this.appRef.attachView(componentRef.hostView);
    
      // Get DOM element from component
      const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
        
    
      domElem.id = "details-" + shop['id'];
    
      row.child(domElem).show();
      row.$('#row-' + shop['id']).addClass('shown');
    }
  }
}