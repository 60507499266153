import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import {Shop} from '../services/shop'
import { environment } from '../../environments/environment';
import {ShopService} from '../services/shop.service'


@Component({
  selector: 'app-shopcatalogs-component',
  templateUrl: './shopcatalogs.component.html',
  styleUrls: ['./shopcatalogs.component.css']
})
export class ShopCatalogsComponent implements OnInit {
  @Input()
  public shop: Shop;
  public listCatalogColum1;
  public listCatalogColum2;
  public chkCatalogs;
  public sizeCatalog; 
  public listCatalogs;

  constructor(
    private shopService: ShopService,
  ){
  }

  ngOnInit() {
    this.chkCatalogs = {}
    this.listCatalogs = []
    try{
      this.listCatalogs = getListCatalogs()
    }
    catch{this.listCatalogs=['NO CATALOG FOUND']}
    this.sizeCatalog = this.listCatalogs.length
    var halfCataogSize = Math.round(this.sizeCatalog/2 )
    this.listCatalogColum1 = this.listCatalogs.slice(0,halfCataogSize)
    this.listCatalogColum2 = this.listCatalogs.slice(halfCataogSize,this.sizeCatalog)
  }
  

  ngAfterViewInit(){
    for(var i=0; i<this.sizeCatalog; i++ ){
      var tmp = String(this.listCatalogs[i])
      if (this.shop['catalogs'].indexOf(tmp) > -1){ //check for catalog['id'] in the list chkcatalog init list
        this.chkCatalogs[tmp] = true
        $("#chk-"+tmp+'-'+this.shop['id']).prop("checked", true);
      }
      else{
        this.chkCatalogs[tmp] = false
        $("#chk-"+tmp+'-'+this.shop['id']).prop("checked", false);
      }
    }
  }

  public saveCatalog(ct1,ct2){
    this.shop.catalogs = [];
    for (var ctlg in this.chkCatalogs){
      if (this.chkCatalogs[ctlg]==true){
        this.shop.catalogs.push(ctlg);
      }
    }
    this.shopService.update(this.shop);
  }

  public checkActiveCatalog(catlg){
    this.chkCatalogs[catlg] = !this.chkCatalogs[catlg]
  } 
}

function getListCatalogs(){
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.open( "GET", environment.api.catalogs + "/list", false ); // false for synchronous request
  xmlHttp.send( null );
  var catalogJson = JSON.parse(xmlHttp.responseText)["catalog_list"];
  var catalogsLenght = catalogJson.length
  var catalogsName = []
  for (var i=0; i<catalogsLenght; i++){
    catalogsName.push(catalogJson[i].name)
  }
  return catalogsName;

}


