import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';


@Injectable()
export class ThicknessService {
  thickness: boolean = false;

  private withInfo(closure: (options: HttpHeaders) => void): void {
    let options = new HttpHeaders();
    options = options.set('Content-Type', 'application/json; charset=utf-8');

    closure(options);
  }

  constructor(
    private _http: HttpClient,
    private auth: AuthService,
  ) { }

  public getThickness() {
    this.hasThicknessCRM(this.auth.username()).then((data) => {
      this.thickness = data ? true : false;
    });
  }

  public hasThickness(): boolean {
    return this.thickness;
  }


  public hasThicknessCRM(shop_id: string) {
    return new Promise((resolve, reject) => {
      this._http.get(environment.api.crm + "/shop_products/" + shop_id)
        .subscribe(
          (value: any) => {
            if (value.thickness != null) {
              resolve(true)
            } else {
              resolve(false)
            }
          },
          (error: any) => {
            resolve(false)
          }
        )
    });
  }

  public goToThickness(tag_id: string): Promise<String> {

    let url_fitid = environment.api.measures.concat(tag_id.toString())

    return new Promise(resolve => {
      this.withInfo((options) => {
        options = options.set('Content-Type', 'application/json');
        this._http.get(
          url_fitid,
          { headers: options }
        ).subscribe(
          () => {
            options = options.set('Access-Control-Allow-Credentials', 'true');
            this._http.get(
              environment.api.thickness_api.concat("login"),
              { headers: options, withCredentials: true }
            ).subscribe(
              () => {
                window.open(environment.api.thickness.concat("?fitid=").concat(tag_id.toString()), "_blank").focus();
                resolve("done")
              },
              (error) => {
                console.log("Error on Thickness login")
                console.log(error)
              }
            )
          },
          (error) => {
            console.log("Error on Thickness login")
            console.log(error)
          }
        );
      })
    });
  }

}