import {
  Component,
  Injector,
  ComponentFactoryResolver, ApplicationRef, EmbeddedViewRef,
  OnInit, AfterViewInit,
  Input, Output, Injectable,
  EventEmitter,
} from '@angular/core';
import { Fit } from '../services/fit';
import { FitsService } from '../services/fits.service';
import * as $ from 'jquery';
import 'datatables.net'
import { FitDetailsComponent } from './fit_details.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { AuthService } from '../auth/auth.service';
import { ThicknessService } from '../services/thickness.service';
import { MeasuresService } from '../services/measures.service';

@Component({
  selector: 'app-fits-table',
  templateUrl: './fits_table.component.html',
  styleUrls: ['./fits_table.component.css']
})
export class TableFitsComponent implements OnInit, AfterViewInit{
  @Input()
  public fits: Fit[];

  constructor(
    private _fitsService: FitsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private modalService: BsModalService,
    private auth: AuthService,
    private thickness: ThicknessService,
    private measuresService: MeasuresService
  ) { }

  private dataLoaded = new EventEmitter<void>();
  ngOnInit() {
    this._fitsService.fits
      .subscribe(data => {
        this.fits = data.fits;
        this.dataLoaded.emit();
        var self = this;
      });

    this._fitsService.load();
    this.thickness.getThickness();
    this.measuresService.loadFonts();
  }


  private _table: DataTables.Api = null;  
  ngAfterViewInit() {
    this.dataLoaded.subscribe(() => {
      setTimeout(() => {
        this._table = $('#fitsTable').DataTable({
          destroy: true,
          order: [[this.auth.ismultishop() || this.auth.isadmin() ? 6 : 4, 'desc']],
        });
        $('#loading').addClass('invisible');
        $('#tableContainer').removeClass('invisible');
      }, 500);
    });

  }

  private onRowSelect(fit: Fit) {
    let row = this._table.row('#row-' + fit.TagID);
    if (row.child.isShown()) {
      row.child(false);
      row.$('#row-' + fit.TagID).removeClass('shown');
    } else {
      const componentRef = this.componentFactoryResolver
        .resolveComponentFactory(FitDetailsComponent)
        .create(this.injector);

        componentRef.instance.fit = fit;

      // Attach component to the appRef so that it's inside the ng component tree
      this.appRef.attachView(componentRef.hostView);

      // Get DOM element from component
      const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;

      domElem.id = "details-" + fit.TagID;
      row.child(domElem).show();
      row.$('#row-' + fit.TagID).addClass('shown');
    }
  }

  private onSaveInternalRef(fit: Fit) {
    let body:any ={};
    body.tag_id = fit.TagID
    body.intern_reference = fit.internalRef
    this._fitsService.update(body, (ok) => {
      let selector: string = '#row-' + fit.TagID + ' #internalRefInput';
      let className: string = (ok ? 'save-result-success' : 'save-result-error');
      $(selector).addClass(className);
      setTimeout(() => {
        $(selector).removeClass(className);

        let row = this._table.row('#row-' + fit.TagID);
        row.invalidate();
      }, 750);
    });
  }

}
