import { Component, Input, Inject, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Order } from '../services/order';
import { OrdersService } from '../services/orders.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import Utils from '../utils/utils';
import { AuthService } from '../auth/auth.service';
import { NewOrderDataService } from '../services/neworder.data.service';
import { Promotion, PromotionService } from '../services/promotion.service';
import * as S3 from 'aws-sdk/clients/s3';
import { _getComponentHostLElementNode } from '@angular/core/src/render3/instructions';


@Component({
    selector: 'app-order-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit, AfterViewInit {
    @Input()
    public order: Order;

    private s3 = new S3(environment.catalogaccess);

    constructor(
        private _ordersService: OrdersService,
        private http: HttpClient,
        private auth: AuthService,
        private _dataService: NewOrderDataService,
        private promotionService: PromotionService,
    ) { }

    private label_value: string = "";
    private materials;
    private finishings;
    private colors;
    private materialsName = new Array();

    private materialF;
    private materialL;
    private materialR;
    private ColorF: string = "";
    private ColorR: string = "";
    private ColorL: string = "";
    private FinishF: string = "";
    private FinishR: string = "";
    private FinishL: string = "";
    private currentMaterialSelected = null;
    private currentFinishingSelected: string[] = ['', '', ''];
    private currentColorSelected: string[] = ['', '', ''];

    private ztilt_value: number;
    private fbase_value: number;
    private boxHori_value: number;
    private boxVert_value: number;
    private dbl_value: number;

    private ipd_r: number;
    private ipd_l: number;
    private seght_r: number;
    private seght_l: number;
    private bvd_r: number;
    private bvd_l: number;
    private panto: number;

    private promotion: Promotion = new Promotion();

    @ViewChild('tallaSelector2') tallaSelector2;

    ngOnInit() {

        this.promotionService.get(this.order.shop_id)
        .then(promotion => this.promotion = promotion);

        this.label_value = this.order.frame.personal_label;
        this.materialF = this.order.frame.material[0]
        this.materialL = this.order.frame.material[1]
        this.materialR = this.order.frame.material[2]
        this.currentMaterialSelected = this.materialF
        this.ColorF = this.order.frame.color[0]
        this.ColorR = this.order.frame.color[1]
        this.ColorL = this.order.frame.color[2]
        this.FinishF = this.order.frame.finish[0]
        this.FinishR = this.order.frame.finish[1]
        this.FinishL = this.order.frame.finish[2]
        this.ztilt_value = this.order.lens.ztilt;
        this.fbase_value = this.order.lens.fcrv;
        this.boxHori_value = this.order.lens.hbox;
        this.boxVert_value = this.order.lens.vbox;
        this.dbl_value = this.order.lens.dbl;

        this.ipd_r = this.order.lens.ipd[0];
        this.ipd_l = this.order.lens.ipd[1];
        this.seght_r = this.order.lens.seght[0];
        this.seght_l = this.order.lens.seght[1];
        this.bvd_r = this.order.lens.bvd[0];
        this.bvd_l = this.order.lens.bvd[1];
        this.panto = this.order.lens.panto;
    }

    private IsCommandPreConfirmed() {
        if (this.order.preconfirmation_date != null){
            return true;
        }
    }

    private IsCommandConfirmed() {

        if (this.order.confirmation_date != null || this.order.cancel_date != null) {
            return true;
        } else if (this.order.shipping_date != null) {
            return true;
        } else {
            return false;
        }
    }

    private IsValidTag() {
        var format = /[!¿¡ºª¬@#$%^&*()_+\-=\[\]{};'`ÀÁÄÈÉËÌÍÏÒÓÖÙÚÜ·:"\\|ñÑçÇ;€ ,.<>\/?]+/;

        if (format.test(this.label_value)){
            return false;
        } else {
            return true;
        }
    }

    ngAfterViewInit() {

        if (!this.IsCommandConfirmed() && this.auth.isadmin()) {
            this.tallaSelector2.load(this.order.frame.catalog, this.order.frame.technical_name, this.order.frame.size)
        }

        this._dataService.materials(this.order.frame.catalog)
            .then((data: any) => {
                this.materials = data;
                this.loadMaterialAttributes();
            });
    }

    private onSizeSelection(event) {
        let path_montura = event;
        var data = {
            'file_name': path_montura,
            's3': this.s3
        }

        this.getXml(data)
            .then(this.parseXml.bind(this))
            .then((lensData) => {
                this.order.frame.size = this.tallaSelector2.talla
                this.onSaveFrame({
                    size: this.tallaSelector2.talla
                });
                this.onSaveLens(lensData);
            });
    }

    public getXml(data) {
        // Method based on promise methodology to obtain Xml needed.
        /* data(dict):
            s3(obj): the instance of the amazon s3 bucket
            file_name(str): the path to the needed file
        */
        var s3 = data['s3']
        var file_name = data['file_name']
        return new Promise(function (resolve, reject) {
            s3.getObject({
                Bucket: environment.catalogaccess.bucketName,
                Key: file_name,
            }, (err, data) => {
                if (err) {
                    reject(err);
                }
                resolve(data.Body.toString('utf-8'));
            });
        });
    }

    public parseXml(data) {
        return new Promise( (resolve, reject) => {
            let xml = data
            var parser = new DOMParser();
            var xmlDoc = parser.parseFromString(xml, "text/xml");
            this.ztilt_value = Number(xmlDoc.getElementsByTagName("o2020:ztilt")[0]['textContent']);
            this.fbase_value = Number(xmlDoc.getElementsByTagName("o2020:fbase")[0]['textContent']);
            this.boxHori_value = Number(xmlDoc.getElementsByTagName("o2020:boxing_horizontal")[0]['textContent']);
            this.boxVert_value = Number(xmlDoc.getElementsByTagName("o2020:boxing_vertical")[0]['textContent']);
            this.dbl_value = Number(xmlDoc.getElementsByTagName("o2020:dbl")[0]['textContent']);

            resolve({
                ztilt: this.ztilt_value,
                fcrv: this.fbase_value,
                hbox: this.boxHori_value,
                vbox: this.boxVert_value,
                dbl: this.dbl_value,
            });
        })
    }

    // called from html
    private onSaveComments(field: string) {
        let myOrder: Order = {} as Order;
        myOrder[field] = this.order[field];

        this._ordersService.update(this.order.id, myOrder, (ok) => {
            let selector: string = `#details-${this.order.id} #${field}`;
            let className: string = (ok ? 'save-result-success' : 'save-result-error');
            $(selector).addClass(className);
            setTimeout(() => {
                $(selector).removeClass(className);
            }, 750);
        });
    }

    // called from html
    private pushContent(content: string): void {
        let filename = this.order.id;
        let fn = null;

        switch (content) {
            case 'package': filename += '.zip';
                this.http
                    .get<any>(`${environment.api.orders}/${this.order.id}/${content}?redirect=false`)
                    .subscribe(
                        resp => Utils.pushDownloadUrl(String(resp.location), filename),
                        err => console.log(err)
                    );
                break;
            case 'shape': filename += '.vca';
                this.http
                    .get(`${environment.api.orders}/${this.order.id}/${content}`,
                        { responseType: 'text' }
                    )
                    .subscribe(
                        resp => Utils.pushDownloadContent(resp, filename),
                        err => console.log(err)
                    );
                break;
            default: break;
        }


    }

    private loadMaterialAttributes() {
        this.materials.forEach(element => {
            this.materialsName.push(element.name)

            this.finishings = element.finishing;
            this.colors = element.colors;
        });
    }

    private onChangeMaterial(selectedPart, event) {
        this.materialsName = new Array()
        let selection = event.target || event.srcElement;
        this.currentMaterialSelected = selection.options[selection.selectedIndex].value;
        ['fr', 'rt', 'lt'].forEach((part) => {
            if (selectedPart != part) {
                if (!$('#' + part + 'MatSelect').prop('disabled'))
                    $('#' + part + 'MatSelect').val(this.currentMaterialSelected).trigger('change');
            }
        });

        this.loadMaterialAttributes();

        this.onSaveFrame({
            material: [$("#frMatSelect").val().toString(), $("#rtMatSelect").val().toString(), $("#ltMatSelect").val().toString()],
        });
    }

    private onChangeFinishing() {
        this.onSaveFrame({
            finish: [$("#frFinSelect").val().toString(), $("#rtFinSelect").val().toString(), $("#ltFinSelect").val().toString()],
        })
    }

    private onChangeColor() {
        this.onSaveFrame({
            color: [$("#frColSelect").val().toString(), $("#rtColSelect").val().toString(), $("#ltColSelect").val().toString()],
        })
    }

    // called from html
    private onSaveGlobal(data) {
        let myOrder: Order = {} as Order;
        for (var key in data) { 
            this.order[key] = data[key]; 
            myOrder[key] = data[key];
        }
        this._ordersService.update(this.order.id, myOrder, (ok) => {
            console.log("Order succesfully updated")
        });
    }

    private onSaveLens(data) {
        let myOrder: Order = {lens: {}} as Order;
        for (var key in data) { 
            this.order.lens[key] = data[key]; 
            myOrder.lens[key] = data[key];
        }
        this._ordersService.update(this.order.id, myOrder, (ok) => {
            console.log("Order succesfully updated")
        });
    }

    private onSaveFrame(data) {
        let myOrder: Order = {frame: {}} as Order;
        for (var key in data) { 
            this.order.frame[key] = data[key]; 
            myOrder.frame[key] = data[key];
        }
        this._ordersService.update(this.order.id, myOrder, (ok) => {
            console.log("Order succesfully updated")
        });
    }

    private onPreconfirm() {
        this.order.preconfirmation_date = new Date();
        this._ordersService.update(this.order.id, {
            preconfirmation_date: this.order.preconfirmation_date
        } as Order, (ok) => {
            console.log("Order succesfully updated");
        });        
    }
    
    private onConfirm(date?: Date) {
        if(!this.order.preconfirmation_date){
            this.order.preconfirmation_date = new Date();
            this.order.confirmation_date = this.order.preconfirmation_date;
            this._ordersService.update(this.order.id, {
                preconfirmation_date: this.order.confirmation_date,
                confirmation_date: this.order.confirmation_date
            } as Order, (ok) => {
                console.log("Order succesfully updated")
            });
        }
        else{
            this.order.confirmation_date = new Date();
            this._ordersService.update(this.order.id, {
                confirmation_date: this.order.confirmation_date
            } as Order, (ok) => {
                console.log("Order succesfully updated")
            });
        }
    }


    private onCancel(date?: Date) {
        if(!this.order.cancel_date){
            this.order.cancel_date = new Date();
            this._ordersService.update(this.order.id, {
                cancel_date: this.order.cancel_date,
            } as Order, (ok) => {
                console.log("Order succesfully updated")
            });
        }
        else{
            this.order.cancel_date = new Date();
            this._ordersService.update(this.order.id, {
                cancel_date: this.order.cancel_date
            } as Order, (ok) => {
                console.log("Order succesfully updated")
            });
        }
    }

    private onChangeMaker() {
        this._ordersService.update(this.order.id, {
            maker: $("#makerSelector").val().toString()
        } as Order, (ok) => {
            if (ok) console.log("maker updated");
            else console.log("maker update request fails");
        })
    }

    private onChangePricelist() {
        this._ordersService.update(this.order.id, {
            price_code: this.order.price_code
        } as Order);
    }

    private IsValidationNeeded() {
        return (['VIP', 'UNIFORMIDAD'].includes(this.order.price_code) && !this.order.confirmation_date);
    }

    private IsPriceAdmin() {
        return this.auth.pricelist_admin();
    }

    private IsAdmin(){
        return this.auth.isadmin();
    }

    private DisableDropDown(){
        
        if (!this.IsCommandPreConfirmed() && !this.IsCommandConfirmed()) {
            return false;
        } else if ((this.IsPriceAdmin() && !this.IsCommandConfirmed()) || (this.IsAdmin()  && !this.IsCommandConfirmed())) {
            return false;
        } 
        else {
            return true;
        }
        //[disabled]="IsCommandConfirmed() || (IsCommandPreConfirmed() && !IsPriceAdmin()) || (!auth.isadmin() && order.price_code=='SIN_CARGO')"> 
    }

    private 

}

