import { Component, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Fit } from '../services/fit';
import { SettingsService } from '../services/settings.service';
import { HttpClient } from '@angular/common/http';
import Utils from '../utils/utils';
import { tzStrings, tzDefault } from '../utils/timezones';
import { AuthService } from '../auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ThicknessService } from '../services/thickness.service';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}


@Component({
    selector: 'app-settings-details',
    templateUrl: './settings_details.component.html',
    styleUrls: ['./settings_details.component.css']
})
export class SettingsDetailsComponent implements AfterViewInit {
    @Input()
    public shop: any;
    public name_lopd: any;
    public mail_lopd: any;
    public data_lopd: any;
    public language: any;
    public time_zone: any;
    private timeZones:any = tzStrings;
    public video_metadata: any;
    public video_filename: any;
    public screen_saver_enabled: boolean = false;
    public height: any;
    public scanner_name: any;
    public centrator_name: any;
    public thinmax: any;
    public thinmax_name: any;
    public thinmax_negative: any;
    public thinmax_negative_name: any;
    public extreme: any;
    public extreme_name: any;
    public high: any;
    public high_name: any;
    public curved: any;
    public curved_name: any;
    public bbox_ia_tech: boolean=false;
    private imageHeaders: string = 'data:image/png;base64,';
    private video_file: any;
    private video_selected: boolean = true;
    private video_uploaded: boolean;
    private uploadPercentage: Number = 0;
    private uploadingVideo: boolean = false;
    private show_upload_video_button: boolean = false;

    @ViewChild('output_image') outputImage: ElementRef;
    @ViewChild('output_image2') outputImage2: ElementRef;
    @ViewChild('output_image3') outputImage3: ElementRef;
    @ViewChild('output_image4') outputImage4: ElementRef;
    @ViewChild('output_image5') outputImage5: ElementRef;

    public img_log_left: any;
    constructor(
        private _settingsService: SettingsService,
        private http: HttpClient,
        private auth: AuthService,
        private thickness_service: ThicknessService,
    ) { }

    ngAfterViewInit() {
      document.getElementById('fileInput2').onchange = function(e?: HTMLInputEvent) {
        let ALLOWED_IMAGE_FORMATS=["image/jpeg", "image/png", "image/jpg", "image/bmp"]

        var reader = new FileReader();
        let files: any = e.target.files[0];
        reader.onload = function(self){
          var output = <HTMLImageElement>document.getElementById('output_image2');
          output.src = reader.result as string;
        }

        if(!ALLOWED_IMAGE_FORMATS.includes(files.type)){
          alert("Image type: " + files.type + " is not valid. Valid types are: png, jpg, jpeg and bmp" )
        }else{
          reader.readAsDataURL(files);
        }

      }

      if(this.auth.ismultishop()){
        document.getElementById('fileInput').onchange = function(e?: HTMLInputEvent) {
          let ALLOWED_IMAGE_FORMATS=["image/jpeg", "image/png", "image/jpg", "image/bmp"]

          var reader = new FileReader();
          let files: any = e.target.files[0];
          reader.onload = function(self){
            var output = <HTMLImageElement>document.getElementById('output_image');
            output.src = reader.result as string;
          }
          
          if(!ALLOWED_IMAGE_FORMATS.includes(files.type)){
            alert("Image type: " + files.type + " is not valid. Valid types are: png, jpg, jpeg and bmp" )
          }else{
            reader.readAsDataURL(files);
          }

        }
        if(document.getElementById('fileInput3')!=null){
          document.getElementById('fileInput3').onchange = function(e?: HTMLInputEvent) {
            let ALLOWED_IMAGE_FORMATS=["image/jpeg", "image/png", "image/jpg", "image/bmp"]

            var reader = new FileReader();
            let files: any = e.target.files[0];
            reader.onload = function(self){
              var output = <HTMLImageElement>document.getElementById('output_image3');
              output.src = reader.result as string;
            }

            if(!ALLOWED_IMAGE_FORMATS.includes(files.type)){
              alert("Image type: " + files.type + " is not valid. Valid types are: png, jpg, jpeg and bmp" )
            }else{
              reader.readAsDataURL(files);
            }
          }
        }

        if(document.getElementById('fileInput4')!=null){
          document.getElementById('fileInput4').onchange = function(e?: HTMLInputEvent) {
            let ALLOWED_IMAGE_FORMATS=["image/jpeg", "image/png", "image/jpg", "image/bmp"]

            var reader = new FileReader();
            let files: any = e.target.files[0];
            reader.onload = function(self){
              var output = <HTMLImageElement>document.getElementById('output_image4');
              output.src = reader.result as string;
            }
            if(!ALLOWED_IMAGE_FORMATS.includes(files.type)){
              alert("Image type: " + files.type + " is not valid. Valid types are: png, jpg, jpeg and bmp" )
            }else{
              reader.readAsDataURL(files);
            }
          }
        }

        if(document.getElementById('fileInput5')!=null){
            document.getElementById('fileInput5').onchange = function(e?: HTMLInputEvent) {
              let ALLOWED_IMAGE_FORMATS=["image/jpeg", "image/png", "image/jpg", "image/bmp"]

              var reader = new FileReader();
              let files: any = e.target.files[0];
              reader.onload = function(self){
                var output = <HTMLImageElement>document.getElementById('output_image5');
                output.src = reader.result as string;
              }
              if(!ALLOWED_IMAGE_FORMATS.includes(files.type)){
                alert("Image type: " + files.type + " is not valid. Valid types are: png, jpg, jpeg and bmp" )
              }else{
                reader.readAsDataURL(files);
              }
            }
          }
        }

      this._settingsService.getConfig(this.shop)
        .subscribe((data: any) => {
          this.name_lopd = data["lopd"]
          this.mail_lopd = data["mail_lopd"]
          this.data_lopd = data["data_lopd"]
          this.language = data["language"]
          this.time_zone = "time_zone" in data ? data["time_zone"] : tzDefault;
          this.height = data["height"]
          this.scanner_name = data["scanner_name"]
          this.centrator_name = data["centrator_name"]
          this.thinmax = data["thinmax"]
          this.thinmax_name = data["thinmax_name"]
          this.thinmax_negative = data["thinmax_negative"]
          this.thinmax_negative_name = data["thinmax_negative_name"]
          this.extreme = data["extreme"]
          this.extreme_name = data["extreme_name"]
          this.high = data["high"]
          this.high_name = data["high_name"]
          this.curved = data["curved"]
          this.curved_name = data["curved_name"]
          
          if (data['logo_left'] != null)
            this.outputImage2.nativeElement.src = this.imageHeaders + data['logo_left'] as string;
          if (this.auth.isadmin() || this.auth.ismultishop()) {
            if (data['logo_right'] != null)
              this.outputImage.nativeElement.src = this.imageHeaders + data['logo_right'] as string;
            if (data['logo_eyewear'] != null && this.auth.hasOrders() || this.auth.isadmin())
              this.outputImage3.nativeElement.src = this.imageHeaders + data['logo_eyewear'] as string;
            if (data['logo_fit'] != null && this.auth.hasFits() || this.auth.isadmin())
              this.outputImage4.nativeElement.src = this.imageHeaders + data['logo_fit'] as string;
            if (data['logo_backoffice'] != null)
              this.outputImage5.nativeElement.src = this.imageHeaders + data['logo_backoffice'] as string;
          }
          if(data["video_metadata"] && (this.auth.ismultishop() || this.auth.isadmin())){
            this.video_metadata = data['video_metadata'];
            this.video_filename = this.video_metadata["original_name"];
            this.screen_saver_enabled = this.video_metadata['enabled'];
          }
          this.bbox_ia_tech = data["bbox_ia_tech"]
        });

    }

  private compareTimeZones(gmt1:any, gmt2:any) {
      if (gmt1==null || gmt2==null) {
        return false;
      }
      return gmt1.label === gmt2.label;
  }

  private reset_video_file_input(): void {
    const video_element = <HTMLInputElement>document.getElementById("video_filename");
    const input_video = <HTMLInputElement>document.getElementById("fileVideo");
    input_video.value = '';
    video_element.value = '';
  }

  private check_input(event) {
    const video_types = ["mp4", "x-m4v", "ogg", "webm"]
    this.video_file = event.srcElement.files[0];
    this.show_upload_video_button = true;
    this.video_selected = false;
    this.video_uploaded = false;
    this.video_filename = this.video_file.name;

    if(this.video_file.size > 1.5e+7 || !(video_types.includes(this.video_file.type.split("/")[1])))
    {
      let alert_string = "";
      if(this.video_file.size > 1.5e+7) {
        alert_string += "Video size must be under 15MB.\n"
      }
      if(!(video_types.includes(this.video_file.type.split("/")[1]))) {
        alert_string += "Video format must be MP4, WEBM or OGG."
      }
      alert(alert_string);
      this.reset_video_file_input();
    }
  }

  private showSpinnerOnDownloadVideo(show: boolean) {
    let ctrl = $('#download-spinner');
    this.uploadingVideo = show;
    if (show) {
      ctrl.removeClass('d-none');
    } else {
      ctrl.addClass('d-none');
    }
  }

  private upload_video(): void {
    this.showSpinnerOnDownloadVideo(true);
    let that = this;
    this.video_metadata = {
      "original_name": this.video_file.name,
      "encoding": this.video_file.type,
      "size": this.video_file.size
    };

    this._settingsService.uploadVideo(
      this.shop,
      this.video_file,
      this.screen_saver_enabled
    ).subscribe(
      (data) => {
        let xhr = new XMLHttpRequest();
        xhr.open('PUT', data['url'], true);
        xhr.setRequestHeader('Content-Type', that.video_file.type);
        xhr.onload = () => {
          if (xhr.status === 200) {
            this.video_uploaded = true;
            alert('Video uploaded.');
            this.showSpinnerOnDownloadVideo(false);
            this.show_upload_video_button = false;
            this.uploadPercentage = 0;
          }
        };
        xhr.upload.onprogress = (evt) => {
          this.uploadPercentage = Math.ceil((evt.loaded / evt.total) * 100);
        }
        xhr.onerror = () => {
          alert('Error while uploading video.\nPlease try again or contact Costumer Support.');
          this.showSpinnerOnDownloadVideo(false);
          this.show_upload_video_button = false;
        }
        xhr.send(that.video_file);
      })
  }

  private applyContent(): void {
    let out_vars = [
      this.name_lopd,
      this.mail_lopd,
      this.data_lopd,
      this.language,
      this.time_zone,
      this.height,
      this.scanner_name,
      this.centrator_name,
      this.thinmax,
      this.thinmax_name,
      this.thinmax_negative,
      this.thinmax_negative_name,
      this.extreme,
      this.extreme_name,
      this.high,
      this.high_name,
      this.curved,
      this.curved_name,
      this.screen_saver_enabled,
      this.bbox_ia_tech
    ]

    let out_keys = [
      "lopd",
      "mail_lopd",
      "data_lopd",
      "language",
      "time_zone",
      "height",
      "scanner_name",
      "centrator_name",
      "thinmax",
      "thinmax_name",
      "thinmax_negative",
      "thinmax_negative_name",
      "extreme",
      "extreme_name",
      "high",
      "high_name",
      "curved",
      "curved_name",
      "screen_saver_enabled",
      "bbox_ia_tech"
    ]

    let data_to_report  = {}
    let list_promises = []

    if((<HTMLInputElement>document.getElementById('fileInput2')).files[0]){
      let p1 = this.ResizeLogos('fileInput2').then(function(base64Img) {return {"logo_left": base64Img }; });
      list_promises.push(p1)
    }

    if(this.auth.ismultishop() && (<HTMLInputElement>document.getElementById('fileInput')).files[0]){
      let p2 = this.ResizeLogos('fileInput').then(function(base64Img) {return {"logo_right": base64Img }; });
      list_promises.push(p2)
    }

    if(document.getElementById('fileInput3')!=null){
      if((<HTMLInputElement>document.getElementById('fileInput3')).files[0]){
        let p3 = this.ResizeLogos('fileInput3').then(function(base64Img) {return {"logo_eyewear": base64Img }; });
        list_promises.push(p3)
      }
    }

    if(document.getElementById('fileInput4')!=null){
      if((<HTMLInputElement>document.getElementById('fileInput4')).files[0]){
        let p4 = this.ResizeLogos('fileInput4').then(function(base64Img) {return {"logo_fit": base64Img }; });
        list_promises.push(p4)
      }
    }

    if(document.getElementById('fileInput5')!=null){
      if((<HTMLInputElement>document.getElementById('fileInput5')).files[0]){
        let p5 = this.ResizeLogos('fileInput5').then(function(base64Img) {return {"logo_backoffice": base64Img }; });
        list_promises.push(p5)
      }
    }

    Promise.all(list_promises).then(logos_base64 => { 
      out_vars.forEach((element,i)=> {
        if (typeof element !== 'undefined' && element !== null) {
          data_to_report[out_keys[i]] = element
        }
      });

      logos_base64.forEach((element)=> {
        data_to_report = Object.assign({}, data_to_report, element);
      });

      this._settingsService.postConfig(this.shop, data_to_report)
      .subscribe((data: any) => {
        if(data){
          alert("Settings correctly updated")
        }else{
          alert("Something went wrong")
        }
      });
    });
  }

  public ResizeLogos(html_input: any) {
    return new Promise(function(resolve, reject) {
    var MAX_WIDTH = 200;
    var MAX_HEIGHT = 50;
  
    if ((<any>window).File && (<any>window).FileReader && (<any>window).FileList && (<any>window).Blob) {
      
        var filesToUploads = <HTMLInputElement>document.getElementById(html_input);
        var file = filesToUploads.files[0];
        if (file) {
  
            var reader = new FileReader();
            // Set the image once loaded into file reader
            reader.onload = function(e) {
  
                var img = document.createElement("img");
                img.src = e.target.result as string;
  
                var canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
  
                var width = img.width;
                var height = img.height;
                
                /*var width_resc = MAX_WIDTH/width
                var height_resc = MAX_HEIGHT/height
  
                if (width_resc < height_resc) {
                    if (width > MAX_WIDTH) {
                        height *= width_resc;
                        width *= width_resc;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        height *= height_resc;
                        width *= height_resc;
                    }
                }*/
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                console.log(canvas)
                ctx.drawImage(img, 0, 0, width, height);
                let dataurl = canvas.toDataURL(file.type);
                resolve(dataurl);
            }
            reader.readAsDataURL(file);
  
        }
  
    } else {
        alert('The automatically logo resezing is not fully supported in you browser. Please, use Google Chrome or Firefox.');
    }
  });
  }
}
