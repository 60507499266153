import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Shop } from './shop'
import { environment } from '../../environments/environment';


@Injectable()
export class ShopService {

  constructor(
    private _http: HttpClient,
  ) { }

  public load(): Observable<Shop[]> {
    return new Observable<Shop[]>(observer => {
      this._http.get(environment.api.shops)
      .subscribe(
        (shops: Shop[]) => { observer.next(shops) },
        (error) => { console.log(error) }
      );
    });
  }

  public update(shop: any, callback?: (ok: boolean) => void) {
    let url = environment.api.shops+ "/" + shop.id;
    this._http.put(url, shop)
      .subscribe(
        (res: Response) => {
          if (callback) {
            callback(res.ok);
          }
        },
        error => console.log(error)
      );
  }

  public get(id: string): Observable<Shop> {
    return new Observable<Shop>(observer => {

      this.load()
        .subscribe(
          (shops: Shop[]) => {
            let shop: Array<Shop> = shops.filter(shop => shop.id == id);
            if (shop.length == 0) {
              observer.next({
                id: id,
                catalogs: null,
                shippingAddress: {
                  name: '',
                  address: []
                }
              })
            } else {
              observer.next(shop[0]);
            }
          },
          (error) => { console.log(error) }
        );

    });
  }

}