import { 
    Component, Injector,
    ComponentFactoryResolver, ApplicationRef, EmbeddedViewRef,
    Renderer2, ElementRef,
  } from '@angular/core';
  import { Fit } from '../services/fit';
  import { ShopService } from '../services/shop.service';
  import { Shop } from '../services/shop';
  
  
  @Component({
    selector: 'app-fits',
    templateUrl: './fits.component.html',
    styleUrls: ['./fits.component.css']
  })
  export class FitsComponent {
    constructor(
      private componentFactoryResolver: ComponentFactoryResolver,
      private injector: Injector,
      private appRef: ApplicationRef,
      private renderer: Renderer2,
      private me: ElementRef,
      private shopService: ShopService,
    ) { }

  }
  