import { 
  NgModule, CUSTOM_ELEMENTS_SCHEMA 
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDialogModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// 3rd-party components
import { DataTablesModule } from 'angular-datatables';
import { ModalModule } from 'ngx-bootstrap/modal';

import { OrdersComponent } from './orders/orders.component';
import { TableComponent } from './orders/table.component';
import { DetailsComponent } from './orders/details.component';
import { OrdersService } from './services/orders.service';
import { DeliverynoteComponent } from './orders/deliverynote.component';
import { NewOrderForm, ShopSelectorComponent, PartsSelectorComponent } from './orders/neworder.component';
import { NewOrderDataService } from './services/neworder.data.service';

import { FitsComponent } from './fits/fits.component';
import { TableFitsComponent } from './fits/fits_table.component';
import { SettingsComponent } from './settings/settings.component';
import { TableSettingsComponent } from './settings/settings_table.component';
import { FitsService } from './services/fits.service';
import { SettingsService } from './services/settings.service';
import { MeasuresService } from './services/measures.service';
import { FitDetailsComponent } from './fits/fit_details.component';
import { Workaround }  from './services/fits.service';

import { FitsErrorComponent } from './fits/fits_error.component';
import { FitDetailsErrorComponent } from './fits/fit_details_error.component';


import { TopbarComponent } from './topbar/topbar.component';
import { LoginComponent } from './topbar/login/login.component';

import { AppViewComponent } from './app-view.component';

import { DeliverynoteconfirmationComponent } from './orders/deliverynoteconfirmation.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { ShopCatalogsComponent } from './shops/shopcatalogs.component';
import { SettingsDetailsComponent } from './settings/settings_details.component';


import { CatalogControlsModule } from './controls/catalogcontrols.module';
import { ShopsComponent } from './shops/shops.component'
import { MonturaComponent } from './orders/combomontura.component';
import { SizeselectorComponent } from './orders/neworder.combosize';
import { ShopService } from './services/shop.service';
import { PromotionService } from './services/promotion.service';
import { ThicknessService } from './services/thickness.service';


import { AuthService } from './auth/auth.service';
import { 
  AuthHttpInterceptor, 
  provideTokenizedHttpClient,
} from './auth/httpclient.service';
import { HTTP_NOAUTH, } from './auth/httpclient.def';


@NgModule({
  entryComponents: [
    DetailsComponent,
    FitDetailsComponent,
    FitDetailsErrorComponent,
    ShopCatalogsComponent,
    SettingsDetailsComponent,
    DeliverynoteComponent,
    DeliverynoteconfirmationComponent
  ],
  declarations: [
    AppViewComponent,
    TopbarComponent,
    OrdersComponent,
    DetailsComponent,
    DeliverynoteComponent,
    TableComponent,
    DeliverynoteconfirmationComponent,
    NewOrderForm,
    ShopsComponent,
    ShopSelectorComponent,
    PartsSelectorComponent,
    LoginComponent,
    CatalogsComponent,
    ShopCatalogsComponent,
    SettingsDetailsComponent,
    MonturaComponent,
    SizeselectorComponent,
    FitsComponent,
    TableFitsComponent,
    SettingsComponent,
    TableSettingsComponent,
    FitDetailsComponent,
    FitsErrorComponent,
    FitDetailsErrorComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatDialogModule,   
    MatInputModule, 
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    ModalModule.forRoot(), 
    CatalogControlsModule,
  ],
  providers: [
    AuthService,
    provideTokenizedHttpClient(HTTP_NOAUTH, { excludes: [AuthHttpInterceptor] }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    OrdersService,
    ShopService,
    PromotionService,
    Workaround,
    NewOrderDataService,
    FitsService,
    SettingsService,
    ThicknessService,
    MeasuresService
  ],
  bootstrap: [AppViewComponent]
})
export class AppModule { }
