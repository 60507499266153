import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { SettingsService } from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app-view.component.html',
  styleUrls: ['./app-view.component.css']
})

export class AppViewComponent {
  private isLoggedIn: boolean = false;
  private option = 'home';
  public logo_img: any;

  constructor(
    private auth: AuthService,
    private _settingsService: SettingsService
  ) {
    auth.onAuthChanged
      .subscribe(authState => {
        this.isLoggedIn = authState != null;
        if (!this.isLoggedIn) {
          this.option = 'home';
        } else {
          this._settingsService.getConfig(this.auth.shop_id())
            .subscribe((data: any) => {
              this.logo_img = data["logo_backoffice"];

              if (!data["logo_backoffice"].includes("data:image/jpg;base64,")) {
                this.logo_img = "data:image/jpg;base64," + this.logo_img;
              }
            },
            error => {
                this.logo_img = '/assets/logo_transparente.png';
                console.log(error);
            });
        }
      });
  }

  private navigation(option: string) {
    this.option = option;
  }
};
