import { Component, OnInit } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import * as moment from 'moment';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.css']
})
export class CatalogsComponent implements OnInit {

  private client: S3 = new S3(environment.catalogaccess);

  private catalogs: string[] = [];
  private selectedCatalog: string = null;
  private frames: string[] = [];
  private selectedFrame: string = null;
  private filesOrFolders = [];


  ngOnInit() {

    this.client.listObjects({
      Bucket: environment.catalogaccess.bucketName,
      Delimiter: '/'
    }, (err, data) => {
      data.CommonPrefixes.forEach((prefix) => {
        this.catalogs.push(prefix.Prefix.replace(/\/$/, ''));
      });
    });

  }

  private onCatalogSelection(catalog) {
    this.selectedCatalog = catalog;

    this.client.listObjects({
      Bucket: environment.catalogaccess.bucketName,
      Delimiter: '/',
      Prefix: this.selectedCatalog + '/'
    }, (err, data) => {

      this.frames = [];
      this.selectedFrame = null;
      $('#frameSelector > .active').removeClass('active');
      $('#elementsTable').addClass('d-none');

      data.CommonPrefixes.forEach((prefix) => {
        this.frames.push(prefix.Prefix
          .replace(/^[^\/]*\//, '')
          .replace(/\/$/, '')
        );
      });
    })
  }

  private onFrameSelection($event) {
    let selection = $event.target || $event.srcElement;
    this.selectedFrame = selection.id;

    $('#frameSelector > .active').removeClass('active');
    $('#frameSelector > #' + selection.id).addClass('active');

    this.client.listObjects({
      Bucket: environment.catalogaccess.bucketName,
      Prefix: this.selectedCatalog + '/' + this.selectedFrame + '/'
    }, (err, data) => {

      this.filesOrFolders = []

      var FOLDER_PATTERN = new RegExp('_\\' + this.selectedCatalog + '/' + this.selectedFrame + '/\\$$');
      var TYPE_PATTERN = new RegExp('\\.([^\\.\\s]{1,10})$');
      var KB = 1024;
      var MB = 1000000;
      var GB = 1000000000;

      function toFriendlySizeName(size) {
        if (size === 0) {
          return '';
        } else if (size < KB) {
          return size + ' B';
        } else if (size < MB) {
          return (size / KB).toFixed(0) + ' KB';
        } else if (size < GB) {
          return (size / MB).toFixed(2) + ' MB';
        }
        return (size / GB).toFixed(2) + ' GB';
      }

      function FileOrFolder(lastModified, etag, size, key) {
        var self = this;
        self.lastModified = lastModified;
        self.etag = etag;
        self.size = size;
        self.key = key;
        // init logic
        self.isFolder = FOLDER_PATTERN.test(self.key);
        self.filename = self.isFolder ? self.key.replace(FOLDER_PATTERN, '') : self.key;
        self.levels = self.filename.split('/');
        self.numLevels = self.levels.length;
        self.simpleFilename = self.levels[self.numLevels - 1];
        self.friendlySizeName = toFriendlySizeName(parseInt(self.size, 10));
        var foundTypes = TYPE_PATTERN.exec(self.simpleFilename);
        self.type = self.isFolder ? 'Folder ' : (foundTypes ? (foundTypes[1].toUpperCase() + ' file') : 'Unknown');
        self.friendlyLastModified = moment(lastModified).format('MMM Do YYYY, hh:mm:ss a');
      }

      data.Contents.forEach((element) => {
        var fileOrFolder = new FileOrFolder(
          element.LastModified,
          element.ETag,
          element.Size,
          element.Key
        );

        this.filesOrFolders.push(fileOrFolder);
      });

      // sort
      this.filesOrFolders.sort(function (left, right) {
        if (left.levels === right.levels) {
          return 0;
        } else if (left.levels < right.levels) {
          return -1;
        }
        return 1;
      });

      $('#elementsTable').removeClass('d-none');
    });
  }

  private pushDownload(element: any) {
    this.client.getSignedUrl('getObject', {
      Bucket: environment.catalogaccess.bucketName,
      Key: element.key,
      Expires: 60
    }, (err, url) => {
      window.open(url, '_blank');
    })
  }
}
