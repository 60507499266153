
class Lens {
    ipd: [number, number];
    seght: [number, number];
    bvd: [number, number];
    panto: number;
    ztilt: number;
    hbox: number;
    vbox: number;
    dbl: number;
    fcrv: number;
}

class Frame {
    name: string;
    personal_label: string;
    material: [string, string, string];
    finish: [string, string, string];
    color: [string, string, string];
    catalog: string; 
    technical_name: string;
    size: string;
    parts: any;
}

export class Order {
    id: string;
    shop_id: string;
    manufacturing_id: string;
    date: Date;
    shipping_date: Date;
    deliveryDate: Date;
    lens: Lens;
    frame: Frame;
    internalRef: string;
    comments: string;
    comments2: string;
    comments3: string;
    catalog: string; 
    technical_name: string; 
    size: string; 
    maker: string;
    preconfirmation_date: Date;
    confirmation_date: Date;
    cancel_date: Date;
    price_code: string;
}

export class OrdersData {
    orders: Order[] = [];
}
