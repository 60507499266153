export const environment ={
  production: true,

  api: {
    orders: 'https://api.madeforyouconcept.com/backoffice/orders',
    shops: 'https://api.madeforyouconcept.com/backoffice/shops',
    catalogs: 'https://api.madeforyouconcept.com/catalogs',
    orderId: 'https://services.internal.horizonsoptical.com/counter/orderid',
    router: 'https://api.madeforyouconcept.com/services/router',
    fits: 'https://api.madeforyouconcept.com/backoffice/tags',
    measures: 'https://api.madeforyouconcept.com/backoffice/measures/',
    thickness: 'https://360depict.invisionsmartoptical.com/',
    thickness_api: 'https://api.360depict.invisionsmartoptical.com/',
    settings: 'https://fits.madeforyouconcept.com/settings',
    crm: 'https://api.crm.internal.horizonsoptical.com',
    finish_fit: 'https://www.fits.retailservices.horizonsoptical.com/',
    fit_api: 'https://api.fits.retailservices.horizonsoptical.com/',
    metadata_tar_api: 'https://api.madeforyouconcept.com/backoffice/tags/create_tar',
    check_folder: 'https://api.madeforyouconcept.com/backoffice/tags/check_folder'
  },

  auth: {
    endpoint: 'https://api.madeforyouconcept.com/auth',
    key: 'gM87QkxPWyrJJcVJ'
  },

  catalogaccess: {
    bucketName: 'made4youconcept-catalogs',
    accessKeyId: 'AKIAIFF6S7RPX4R23HRA',
    secretAccessKey: '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',
    region: 'eu-west-1'
  }
};