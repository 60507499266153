import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../environments/environment';


@Component({
    selector: 'app-catalogselector',
    template: `
        <select id="catalogSelect" class="custom-select mb-3 select-horizons" (change)="onChange($event)">
            <option selected>Choose catalog...</option>
            <option *ngFor="let catalog of catalogs" [selected]="catalog == DefaultCatalog">{{ catalog }}</option>
        </select>
    `
})
export class CatalogselectorComponent implements OnInit {

    readonly DefaultCatalog: string = 'MIMIC_2017_2018';
    private catalogs: string[] = [];
    @Output() onSelection = new EventEmitter<string>();

    ngOnInit() {
        let client: S3 = new S3(environment.catalogaccess);
        client.listObjects({
            Bucket: environment.catalogaccess.bucketName,
            Delimiter: '/'
        }, (err, data) => {
            data.CommonPrefixes.forEach((prefix) => {
                let value = prefix.Prefix.replace(/\/$/, '');
                if (this.DefaultCatalog==value) {
                    this.onSelection.emit(this.DefaultCatalog);
                }

                this.catalogs.push(value);
            });
        });
    }

    private onChange(event) {
        let selection = event.target || event.srcElement;
        this.onSelection.emit(selection.options[selection.selectedIndex].value);
    }
}
