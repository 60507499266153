
import { Component, Inject, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Order } from '../services/order';
import 'datatables.net'
import { environment } from '../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import * as mustache from 'mustache'
import { HttpClient } from '@angular/common/http';
import { NewOrderDataService } from '../services/neworder.data.service';
import { Shop } from '../services/shop';
import { ShopService } from '../services/shop.service'
import { HTTP_NOAUTH } from '../auth/httpclient.def';


class OrderId {
    order_id: string;
    manufacturing_id: string;
}

@Component({
    selector: 'app-shopselector',
    template: `
        <select class="custom-select mb-3 select-horizons" (change)="onChange($event)">
            <option selected>Choose shop...</option>
            <option *ngFor="let shop of shops">{{ shop.id }}</option>
        </select>
    `
})
export class ShopSelectorComponent implements OnInit {

    private shops: Shop[] = [];

    constructor(
        private shopService: ShopService,
    ) { }

    ngOnInit() {
        this.shopService.load()
        .subscribe(
          (shops: Shop[]) => {
            try{
                this.shops = shops;
            }
            catch{
            }
          }
        )
    }

    public currentSelection: string = null;
    private onChange(event) {
        let selection = event.target || event.srcElement;
        this.currentSelection = selection.options[selection.selectedIndex].value;
    }

    public reset() {
        $('select option').first().prop('selected', true);
    }
}

@Component({
    selector: 'app-partsselector',
    template: `
        <div class="form-check form-check-inline">
            <input class="form-check-input normal-parts" type="checkbox" (change)="onPartsToggle($event)" id="fr" value="fr" checked>
            <label class="form-check-label" for="fr">frontal</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input normal-parts" type="checkbox" (change)="onPartsToggle($event)" id="rt" value="rt" checked>
            <label class="form-check-label" for="rt">r-temple</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input normal-parts" type="checkbox" (change)="onPartsToggle($event)" id="lt" value="lt" checked>
            <label class="form-check-label" for="lt">l-temple</label>
        </div>
        <div class="form-check form-check-inline invisible">
            <input class="form-check-input" type="checkbox" (change)="onSampleToggle($event)" id="sample" value="sample">
            <label class="form-check-label" for="sample">sample</label>
        </div>
    `
})
export class PartsSelectorComponent {

    @Output() onToggleSelection = new EventEmitter<any>();
    private onPartsToggle(event) {
        if ( // avoid uncheck everything if enable(=sample is unchecked!)
            !$('#fr').prop('checked') &&
            !$('#rt').prop('checked') &&
            !$('#lt').prop('checked')
        ) {
            let selection = event.target || event.srcElement;
            selection.checked = !selection.checked;
        }
        else {
            this.onToggleSelection.emit(this.selection());
        }
    }

    private onSampleToggle(event) {
        let selection = event.target || event.srcElement;
        $('.normal-parts').prop('checked', !selection.checked);
        $('.normal-parts').prop('disabled', selection.checked);

        this.onToggleSelection.emit(this.selection());
    }

    public selection(): any {
        return {
            fr: $('#fr').prop('checked'),
            rt: $('#rt').prop('checked'),
            lt: $('#lt').prop('checked'),
            sample: $('#sample').prop('checked')
        }
    }

    public validation(): boolean {
        let parts = this.selection();

        if (parts.fr && (parts.rt ? !parts.lt : parts.lt)) return false;
        else return true;
    }

    public reset() {
        ['fr', 'rt', 'lt'].forEach((part) => {
            $('#' + part).prop('checked', true);
        });
    }
}

@Component({
    selector: 'app-new-order',
    templateUrl: './neworder.component.html',
    styleUrls: ['./neworder.component.css']
})
export class NewOrderForm {
    // Class implemented to generate a command on-demand
    @Input()
    public order: Order;

    private catalog: string = null;
    private frame: string = null;
    private size: string = null;

    private s3 = new S3(environment.catalogaccess);

    constructor(
        private _dataService: NewOrderDataService,
        @Inject(HTTP_NOAUTH) private _http: HttpClient,
    ) { }

    // form fields
    private iDist1_value: string  = "";
    private iDist2_value: string  = "";
    private fHeight1_value: string  = "";
    private fHeight2_value: string = "";
    private bDist1_value: string  = "";
    private bDist2_value: string  = "";
    private panto_value: string  = "";
    private ztilt_value: string  = "";
    private fbase_value: string  = "";
    private boxHori_value: string  = "";
    private boxVert_value: string  = "";
    private dbl_value: string  = "";
    private model_value: string  = "";
    private label_value: string  = "";

    private reset() {
        this.iDist1_value = "";
        this.iDist2_value = "";
        this.fHeight1_value = "";
        this.fHeight2_value = "";
        this.bDist1_value = "";
        this.bDist2_value = "";
        this.panto_value = "";
        this.ztilt_value = "";
        this.fbase_value = "";
        this.boxHori_value = "";
        this.boxVert_value = "";
        this.dbl_value = "";
        this.model_value = "";
        this.label_value = "";
        
        this.shopSelector.reset();

        this.frameSelector.reset();
        this.onCatalogSelection(this.catalog);
        this.currentMaterialSelected=this.materials[0].name;
        $('#frMatSelect').val(this.currentMaterialSelected).trigger('change');

        this.partsSelector.reset();
        this.onPartsSelection(this.partsSelector.selection());

        ['fr', 'rt', 'lt'].forEach((part) => {
            ['MatSelect', 'FinSelect', 'ColSelect'].forEach((tag) => {
                let selector = '#' + part + tag;
                $(selector + ' option').first().prop('selected', true);
            });
        });
    }

    readonly NotApplicableValue: string = "N/A"
    private onPartsSelection(parts) {
        ['fr', 'rt', 'lt'].forEach((part) => {
            ['MatSelect', 'FinSelect', 'ColSelect'].forEach((tag) => {
                let selector = '#' + part + tag;
                if (parts[part]) {
                    $(selector + " option[value='" + this.NotApplicableValue + "']").remove();
                    $(selector).prop('disabled', false);

                    if ('MatSelect'==tag) {
                        $(selector).val(this.currentMaterialSelected).trigger('change');
                    }
                } else {
                    $(selector).append(new Option(this.NotApplicableValue, this.NotApplicableValue))
                    $(selector + " option[value='" + this.NotApplicableValue + "'").prop('selected', true);
                    $(selector).prop('disabled', true);
                }
            });
        });
    }

    private currentMaterialSelected = null;
    private currentFinishingSelected: string[] = ['', '', ''];
    private currentColorSelected: string[] = ['', '', ''];
    private onChangeMaterial(selectedPart, event) {
        let selection = event.target || event.srcElement;
        this.currentMaterialSelected = selection.options[selection.selectedIndex].value;
        
        ['fr', 'rt', 'lt'].forEach((part) => {
            if (selectedPart!=part) {
                if (!$('#' + part + 'MatSelect').prop('disabled'))
                    $('#' + part + 'MatSelect').val(this.currentMaterialSelected).trigger('change');
            }
        });

        this.loadMaterialAttributes();
    }

    private materials;
    private finishings;
    private colors;
    private loadMaterialAttributes() {
        this.materials.forEach(element => {
            if (this.currentMaterialSelected == element.name) {
                this.finishings = element.finishing;
                this.colors = element.colors;
            }
        });
    }

    @ViewChild('frameSelector') frameSelector;
    private onCatalogSelection(event) {
        this.catalog = event;
        this.frame = null;
        this.size = null

        this.onSelection.emit({
            catalog: this.catalog,
            frame: this.frame,
            size: this.size
        });
        this.frameSelector.load(this.catalog);

        this._dataService.materials(this.catalog)
        .then((data: any) => {
            this.materials = data;
            this.currentMaterialSelected = data[0].name;
            this.loadMaterialAttributes();
        });
    }

    @ViewChild('tallaSelector') tallaSelector;
    @Output() onSelection = new EventEmitter<object>();
    private onMonturaSelection(event) {
        this.frame = event;

        this.onSelection.emit({
            catalog: this.catalog,
            frame: this.frame,
            size: this.size
        });
        this.tallaSelector.load(this.catalog, this.frame);

        if (this.frame != null) {
            this._dataService.framename(this.catalog, this.frame)
            .then((res: string) => {
                this.model_value = res;
            });
        }
    }

    @Output() onSelectionT = new EventEmitter<object>();
    private onSizeSelection(event) {
        let path_montura = event;
        var data = {
            'file_name': path_montura,
            's3': this.s3
        }
        this.size = path_montura.split('/')[path_montura.split('/').length - 1].split('.')[0]
        this.getXml(data).then(this.parseXml.bind(this))

    }

    private IsValidTag() {
        var format = /[!¿¡ºª¬@#$%^&*()_+\-=\[\]{};'`ÀÁÄÈÉËÌÍÏÒÓÖÙÚÜ·:"\\|ñÑçÇ;€ ,.<>\/?]+/;

        if (format.test(this.label_value)){
            return false;
        } else {
            return true;
        }
    }

    @ViewChild('shopSelector') shopSelector;
    @ViewChild('partsSelector') partsSelector;
    private onClick_Create() {

        if (!this.partsSelector.validation()) {
            alert('invalid parts selection!')
            return;
        }

        let parts = this.partsSelector.selection();

        this._dataService.materialid(this.currentMaterialSelected, this.catalog)
        .then((matId) => {
            var view = {
                catalog: this.catalog,
                shop_id: this.shopSelector.currentSelection,
                order_id: null,
                manufact_id: null,
                frame: this.frame,
                size: this.size,
                comercial_frame: this.model_value,
                seght_r: this.fHeight1_value,
                seght_l: this.fHeight2_value,
                panto: this.panto_value,
                bvd_r: this.bDist1_value,
                bvd_l: this.bDist2_value,
                hbox: this.boxHori_value,
                vbox: this.boxVert_value,
                dbl: this.dbl_value,
                idist_R: this.iDist1_value,
                idist_L: this.iDist2_value,
                ztilt: this.ztilt_value,
                fcrv: this.fbase_value,
                personal_label: this.label_value,
                materialF: $("#frMatSelect").val(),
                materialR: $("#rtMatSelect").val(),
                materialL: $("#ltMatSelect").val(),
                matId: matId,
                colorF: $("#frColSelect").val(),
                colorR: $("#rtColSelect").val(),
                colorL: $("#ltColSelect").val(),
                finishF: $("#frFinSelect").val(),
                finishR: $("#rtFinSelect").val(),
                finishL: $("#ltFinSelect").val(),
            };
             
            let httpClient: HttpClient = this._http;

            httpClient.get('/../assets/neworder.xml.template', {responseType:'text'})
            .subscribe((template: string) => {
                let content: string = mustache.render(template, view);

                let formData: FormData = new FormData();
                formData.append('file', content);
                formData.append('bypassNorm', '1');
                formData.append('parts', JSON.stringify(parts));

                httpClient.post(
                    environment.api.router,
                    formData,
                    { responseType: 'text' }
                )
                .subscribe(
                    (r) => {
                        if (r) {
                            var parseString = require('xml2js').parseString;
                            parseString(r, function (err, result) {
                                console.dir(result);
                                alert(
                                    "new order created: " +
                                    result["o2020:WIB"]["o2020:Request"][0]
                                    ["o2020:Request"][0]["o2020:Order_ID"][0]
                                );
                            });
                            this.reset();

                        } else {
                            alert("failing to create order!");
                        }
                    },
                    error => {
                        console.log(error)
                        alert("failing to create order!");
                    }
                );
            })
        });
    }

    public getXml(data) {
        // Method based on promise methodology to obtain Xml needed.
        /* data(dict):
            s3(obj): the instance of the amazon s3 bucket
            file_name(str): the path to the needed file
        */
        var s3 = data['s3']
        var file_name = data['file_name']
        return new Promise(function (resolve, reject) {
            s3.getObject({
                Bucket: environment.catalogaccess.bucketName,
                Key: file_name,
            }, (err, data) => {
                if (err) {
                    reject(err);
                }
                resolve(data.Body.toString('utf-8'));
            });
        });
    }

    public parseXml(data) {
        let xml = data
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(xml, "text/xml");
        let ztilt = xmlDoc.getElementsByTagName("o2020:ztilt")[0]['textContent']
        let fbase = xmlDoc.getElementsByTagName("o2020:fbase")[0]['textContent']
        let boxHori = xmlDoc.getElementsByTagName("o2020:boxing_horizontal")[0]['textContent']
        let boxVert = xmlDoc.getElementsByTagName("o2020:boxing_vertical")[0]['textContent']
        let dbl = xmlDoc.getElementsByTagName("o2020:dbl")[0]['textContent']

        this.ztilt_value = ztilt
        this.fbase_value = fbase
        this.boxHori_value = boxHori
        this.boxVert_value = boxVert
        this.dbl_value = dbl
    }

}
