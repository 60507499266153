import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../environments/environment';


@Component({
    selector: 'app-monturaselector',
    template: `
        <select class="custom-select mb-3 select-horizons" (change)="onChange($event)">
            <option selected>Choose frame...</option>
            <option *ngFor="let frame of frames">{{ frame }}</option>
        </select>
    `
})
export class MonturaComponent {

    private frames: string[] = [];
    @Output() onSelection = new EventEmitter<string>();

    public load(catalog: string) {
        this.frames = [];

        let client: S3 = new S3(environment.catalogaccess);
        client.listObjects({
            Bucket: environment.catalogaccess.bucketName,
            Delimiter: '/',
            Prefix: catalog + '/'
        }, (err, data) => {

            data.CommonPrefixes.forEach((prefix) => {
                this.frames.push(prefix.Prefix
                    .replace(/^[^\/]*\//, '')
                    .replace(/\/$/, '')
                );
            });
        })
    }

    private onChange(event) {
        let selection = event.target || event.srcElement;
        this.onSelection.emit(selection.options[selection.selectedIndex].value);
    }
    
    public reset() {
        this.frames = [];
        this.onSelection.emit(null);
    }
}