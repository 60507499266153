import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTP_NOAUTH } from '../auth/httpclient.def';
import { environment } from '../../environments/environment'


@Injectable()
export class NewOrderDataService {

    constructor(@Inject(HTTP_NOAUTH) private http: HttpClient) { }

    public framename(catalog: string, frame: string) {
        return new Promise((resolve, reject) => {
            if (frame != null && catalog != null){
                this.http.get(environment.api.catalogs + "/frame/" + catalog + "/" + frame)
                .subscribe(
                    (info => { resolve(info['commercial_name']) } ),
                    (error) => { console.log(error); reject(frame + 'not found'); }
                );
            }
        });
    }

    public materials(catalog: string): Promise<any[]> {

        let promise = new Promise<any[]>((resolve, reject) => {
            this.http.get(environment.api.catalogs + "/material/" + catalog)
            .subscribe(
              ((info: any[]) => resolve(info) ),
            );
        });
        return promise;
    }

    public materialid(materialname: string, catalog: string) {
        
        let promise = new Promise((resolve, reject) => {
            this.materials(catalog).then((data) => {
                data.forEach((material) => {
                    if (material.name == materialname) {
                        resolve(material.id);
                    }
                });

                reject();
            })
        });
        return promise;
    }
}