import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { translations, fontsList } from './measures';
import { jsPDF } from 'jspdf';

@Injectable()
export class MeasuresService {
    private logoHeight: number = null;
    private background = new Image();
    private pdf: any;

    constructor(
        private _settingsService: SettingsService
    ) {
        this.background.src = '../assets/background.png';
        this.logoHeight = this.convertPx2mm(38);
    }

    private getShopData(shopID: string) {
        return new Promise((resolve, reject) => {
            this._settingsService.getConfig(shopID).subscribe((data: any) => {
                resolve({
                    logoLeft: data["logo_left"],
                    logoRight: data["logo_right"],
                    language: data["language"],
                });
            })
        });
    }

    public loadFonts() {
        let callAddFont = function () {
            Object.keys(fontsList).forEach(function (key: string) {
                this.addFileToVFS(key, fontsList[key].font);
                this.addFont(key, fontsList[key].name, fontsList[key].type);
            }.bind(this))
        }
        jsPDF.API.events.push(['addFonts', callAddFont]);
    }

    private convertPx2mm(dimension: number) {
        return (dimension * 25.4) / 96;
    }

    private getImageDimensions(file: string) {
        return new Promise(function (resolved, rejected) {
            var i = new Image()
            i.onload = function () {
                resolved({ w: i.width, h: i.height })
            };
            i.src = file;
        })
    }

    private calculateResizedWidth(originalWidth: number, originalHeight: number, resizedHeight: number) {
        return (resizedHeight * originalWidth) / originalHeight;
    }


    public async generatePDF(measures: any, codeMeasure: string, internalReference: string, shopID: string, userPhoto: any) {
        //Init variables

        this.pdf = new jsPDF('l', 'mm', 'a4', true);
        const shopData = await this.getShopData(shopID);
        const canvasWidth = this.pdf.internal.pageSize.getWidth();
        const canvasHeight = this.pdf.internal.pageSize.getHeight();
        const nLines = measures.HeightFrame_L === undefined ? 7 : 8;
        const lang = shopData['language'];
        const internalRef = internalReference ? String(internalReference).replace(/\n/g, " ") : "";

        //add background Image
        this.pdf.addImage(this.background, 'PNG', 10, 20, canvasWidth - 20, canvasHeight - 40, "background", 'FAST')

        //Add left logo
        if (shopData['logoLeft'] !== undefined) {
            var logoLeftDimensions = await this.getImageDimensions('data:image/png;base64,' + shopData['logoLeft']);
            let logoLeftWidth = this.convertPx2mm(this.calculateResizedWidth(logoLeftDimensions['w'], logoLeftDimensions['h'], 38));
            this.pdf.addImage(shopData['logoLeft'], 'PNG', 13, 23, logoLeftWidth, this.logoHeight, "logo_left", 'FAST');
        }

        //Add right logo
        if (shopData['logoRight'] !== undefined) {
            var logoRightDimensions = await this.getImageDimensions('data:image/png;base64,' + shopData['logoRight']);
            let logoRightWidth = this.convertPx2mm(this.calculateResizedWidth(logoRightDimensions['w'], logoRightDimensions['h'], 38));
            this.pdf.addImage(shopData['logoRight'], 'PNG', canvasWidth - 13 - logoRightWidth, 23, logoRightWidth, this.logoHeight, "logo_right", 'FAST');
        }

        //Add User photo
        this.pdf.addImage(userPhoto, 'JPEG', 122, 20, 50.7, 37, "user_photo", 'FAST');
        //this.pdf.setDrawColor(255, 255, 255);
        //this.pdf.rect(122, 20, 50.7, 37)

        //Code measure
        this.pdf.setFontSize(8.5);
        this.pdf.setTextColor(255, 255, 255);
        if (lang == "th") this.pdf.setFont("THSarabunNew", 'normal');
        else this.pdf.setFont("Lato-Light", "bold");
        this.pdf.text(`${translations.RESULTS.code_measure[lang]}   ${codeMeasure}`, canvasWidth - 15, 55, { align: "right" });

        //draw table lines and results
        this.pdf.setDrawColor(255, 255, 255);
        this.pdf.setLineWidth(0.1);

        if (lang == "th") this.pdf.setFont("THSarabunNew", 'normal');
        else this.pdf.setFont('Lato-Light', 'bold');
        const h_string_bold = 5.5 - (this.pdf.getTextDimensions("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPKRSTUVWXYZ()º").h / 2);
        const h_number = 5.5 - (this.pdf.getTextDimensions("0123456789").h / 2);

        //draw table lines
        for (let x = 105; x <= (105 + nLines * 5.5); x += 5.5)
            this.pdf.line(185, x, 278, x);

        //header results table
        const w_right = this.pdf.getTextDimensions(translations.RESULTS.right_eye[lang]).w / 2;
        const w_left = this.pdf.getTextDimensions(translations.RESULTS.left_eye[lang]).w / 2;
        this.pdf.text(translations.RESULTS.right_eye[lang], 240, 105 + h_string_bold, { align: 'center' });
        this.pdf.text(translations.RESULTS.left_eye[lang], 260, 105 + h_string_bold, { align: 'center' });

        if (lang == "th") this.pdf.setFont("THSarabunNew", 'normal');
        else this.pdf.setFont('Lato-Light', 'normal');
        const h_string_normal = 5.5 - (this.pdf.getTextDimensions("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPKRSTUVWXYZ()º").h / 2);
        const height_vbd = measures.HeightFrame_L === undefined ? 105 + (3 * 5.5) : 105 + (4 * 5.5);

        //text table results
        this.pdf.text(`${translations.RESULTS.interp_dist[lang]} (mm)`, 186, 105 + 5.5 + h_string_normal);
        this.pdf.text(`${translations.RESULTS.fitting_height[lang]} (mm) ${translations.RESULTS.box[lang]}`, 186, 105 + (5.5 * 2) + h_string_normal);
        if (measures.HeightFrame_L !== undefined) {
            this.pdf.text(`${translations.RESULTS.fitting_height[lang]} (mm) ${translations.RESULTS.rim[lang]}`, 186, 105 + (3 * 5.5) + h_string_normal);
        }
        this.pdf.text(`${translations.RESULTS.vbd[lang]} (mm)`, 186, height_vbd + h_string_normal);
        this.pdf.text(`${translations.RESULTS.panto[lang]} (º)`, 186, height_vbd + (5.5) + h_string_normal);
        this.pdf.text(`${translations.RESULTS.wrap[lang]} (º)`, 186, height_vbd + (5.5 * 2) + h_string_normal);
        this.pdf.text("HBOX / VBOX / DBL (mm)", 186, height_vbd + (5.5 * 3) + h_string_normal);
        this.pdf.text(translations.RESULTS.internal_ref[lang].toUpperCase(), 186, height_vbd + (5.5 * 4.5) + h_string_normal);

        //results table
        this.pdf.setFont('Lato-Light', 'bold');
        this.pdf.text(String(measures.Naso_R), 240 + w_right, 105 + 5.5 + h_number, { align: 'center' }); //ipd left
        this.pdf.text(String(measures.Naso_L), 260 + w_left, 105 + 5.5 + h_number, { align: 'center' }); //ipd right
        this.pdf.text(String(measures.HeightBOX_R), 240 + w_right, 105 + (5.5 * 2) + h_number, { align: 'center' }); //box left
        this.pdf.text(String(measures.HeightBOX_L), 260 + w_left, 105 + (5.5 * 2) + h_number, { align: 'center' }); //box right
        if (measures.HeightFrame_L !== undefined) {
            this.pdf.text(String(measures.HeightFrame_R), 240 + w_right, 105 + (3 * 5.5) + h_number, { align: 'center' }); //RIM left
            this.pdf.text(String(measures.HeightFrame_L), 260 + w_left, 105 + (3 * 5.5) + h_number, { align: 'center' }); //RIM right
        }
        this.pdf.text(String(measures.BVD_R), 240 + w_right, height_vbd + h_number, { align: 'center' }); //VBD left
        this.pdf.text(String(measures.BVD_L), 260 + w_left, height_vbd + h_number, { align: 'center' }); //VBD right
        this.pdf.text(String(measures.Panto), 240 + ((260 + w_left - 240 + w_right) / 2), height_vbd + (5.5) + h_number, { align: 'center' }); // panto
        this.pdf.text(String(measures.Tilt), 240 + ((260 + w_left - 240 + w_right) / 2), height_vbd + (5.5 * 2) + h_number, { align: 'center' }); // wrap
        this.pdf.text(`${measures.HBOX} / ${measures.VBOX} / ${measures.DBL}`, 240 + ((260 + w_left - 240 + w_right) / 2), height_vbd + (5.5 * 3) + h_number, { align: 'center' }); // hbox / vbox / dbl
        this.pdf.text(String(internalRef).replace(/\n/g, " "), 240 - w_right, height_vbd + (5.5 * 4.5) + h_number, { align: 'left' });

        //LEFT IMAGE TEXT
        if (lang == "th") this.pdf.setFont("THSarabunNew", 'normal');
        else this.pdf.setFont('Lato-Light', 'regular');

        this.pdf.text(translations.RESULTS.wrap[lang].toUpperCase(), 231, 98, { align: 'center' });
        this.pdf.setFontSize(8);
        this.pdf.text(translations.RESULTS.right_eye[lang], 167, 110, { align: 'left' });
        this.pdf.text(translations.RESULTS.left_eye[lang], 167, 117, { align: 'left' });

        //RESULTS IMAGES
        this.pdf.setFont('Lato-Light', 'bold');

        //LEFT IMAGE TOP RESULTS
        this.pdf.setFontSize(8);
        this.pdf.text(`${measures.Tilt}º`, 281, 85);

        //LEFT IMAGE SIDE RESULTS
        this.pdf.text(`${measures.Panto}º`, 156.5, 75, { align: 'center' });
        this.pdf.setFontSize(7);
        this.pdf.text(String(measures.BVD_R), 167, 113, { align: 'left' });
        this.pdf.text(String(measures.BVD_L), 167, 120, { align: 'left' });

        //LEFT IMAGE RESULTS
        this.pdf.setFontSize(8);
        this.pdf.text(String(measures.VBOX), 21, 111, { align: 'right' });
        this.pdf.text(String(measures.Naso_R), 58, 78, { align: 'center' });
        this.pdf.text(String(measures.Naso_L), 74, 78, { align: 'center' });
        this.pdf.text(String(measures.HBOX), 46, 143, { align: 'center' });
        this.pdf.text(String(measures.DBL), 65, 143, { align: 'center' });
        this.pdf.text(String(measures.HeightBOX_R), 40, 118, { align: 'center' });
        this.pdf.text(String(measures.HeightBOX_L), 90, 118, { align: 'center' });

        this.pdf.save(`${codeMeasure}.pdf`);
    }
}