import {
  Component,
  Injector,
  ComponentFactoryResolver, ApplicationRef, EmbeddedViewRef,
  OnInit, AfterViewInit,
  Input, Output,
  EventEmitter,
} from '@angular/core';
import { Order } from '../services/order';
import { OrdersService } from '../services/orders.service';
import * as $ from 'jquery';
import 'datatables.net'
import { DetailsComponent } from './details.component';
import { DeliverynoteconfirmationComponent } from './deliverynoteconfirmation.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { AuthService } from '../auth/auth.service';


@Component({
  selector: 'app-orders-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input()
  public orders: Order[];
  private internal_reference: string[];

  constructor(
    private _ordersService: OrdersService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private modalService: BsModalService,
    private auth: AuthService,
  ) { }


  private dataLoaded = new EventEmitter<void>();
  ngOnInit() {
    this._ordersService.orders
      .subscribe(data => {
        this.orders = data.orders;
        this.internal_reference = this.orders.map(order => order.internalRef);
        this.dataLoaded.emit();
      });

    this._ordersService.load2();
  }

  private _table: DataTables.Api = null;  
  ngAfterViewInit() {
    this.dataLoaded.subscribe(() => {
      setTimeout(() => {
        this._table = $('#ordersTable').DataTable({
          destroy: true,
          autoWidth: false,
          order: [[this.auth.ismultishop() ? 5 : 4, 'desc']],
        });
        $('#loading').addClass('invisible');
        $('#tableContainer').removeClass('invisible');
      }, 500);
    });
  }

  private onRowSelect(order: Order) {
    let row = this._table.row('#row-' + order.id);
    if (row.child.isShown()) {
      row.child(false);
      row.$('#row-' + order.id).removeClass('shown');
    } else {
      const componentRef = this.componentFactoryResolver
        .resolveComponentFactory(DetailsComponent)
        .create(this.injector);

      componentRef.instance.order = order;

      // Attach component to the appRef so that it's inside the ng component tree
      this.appRef.attachView(componentRef.hostView);

      // Get DOM element from component
      const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;

      domElem.id = "details-" + order.id;

      row.child(domElem).show();
      row.$('#row-' + order.id).addClass('shown');
    }
  }

  @Output() onPrintingDeliveryNote = new EventEmitter<Order>();

  private deliveryNote(event: any, order: Order) {
    event.stopPropagation();

    // ask for delivery note date and confirmation
    const initialState = {
      id: order.id,
      date: moment(order.deliveryDate ? order.deliveryDate : new Date()).format('YYYY/MM/DD'),
    };

    let modalRef = this.modalService.show(DeliverynoteconfirmationComponent, { initialState });
    modalRef.content.accept.subscribe((date) => {
      order.deliveryDate = date;
      this.onPrintingDeliveryNote.emit(order);
    })
  }

  private onSaveInternalRef(idx: number) {
    let order = this.orders[idx];

    this._ordersService.update(order.id, {
      internalRef: this.internal_reference[idx]
    } as Order, (ok) => {
      let selector: string = '#row-' + order.id + ' #internalRefInput';
      let className: string = (ok ? 'save-result-success' : 'save-result-error');
      $(selector).addClass(className);
      setTimeout(() => {
        $(selector).removeClass(className);

        let row = this._table.row('#row-' + order.id);
        row.invalidate();
      }, 750);

      this.orders[idx].internalRef = this.internal_reference[idx];
    });
  }
}
