import { Inject, Injectable } from '@angular/core';
import { HTTP_NOAUTH } from '../auth/httpclient.def';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


export class Promotion {
    vip: boolean = false;
    uniformity: boolean = false;

    constructor(vip: boolean = false, uniformity: boolean = false) {
      this.vip = vip;
      this.uniformity = uniformity;
    }
}

@Injectable()
export class PromotionService {

  constructor(
    @Inject(HTTP_NOAUTH) private http: HttpClient
  ) {}

  public get(shop_id: string): Promise<Promotion> {

    return new Promise<Promotion>((resolve, reject) => {
      this.http.get(environment.api.crm + "/shop/" + shop_id)
      .subscribe(
        (value: any) => {
          resolve(new Promotion(
            value.promotion.vip.enabled,
            value.promotion.uniformity.enabled,
          ));
        },
        (error: any) => {
          if (['A', 'B', 'C'].includes(shop_id)) {
            resolve(new Promotion(true, true));
          } else {
            resolve(new Promotion());
          }
        }  
      )
    });
  }
}
