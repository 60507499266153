import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Fit } from '../services/fit';
import { FitsService } from '../services/fits.service';
import { HttpClient } from '@angular/common/http';
import Utils from '../utils/utils';
import * as $ from 'jquery';
import { environment } from '../../environments/environment';


@Component({
    selector: 'app-fit-details-error',
    templateUrl: './fit_details_error.component.html',
    styleUrls: ['./fit_details.component.css']
})
export class FitDetailsErrorComponent {
    @Input()
    public fit: Fit;

    constructor(
        private _fitsService: FitsService,
        private http: HttpClient,
    ) { }

    @ViewChild('img') img: ElementRef;
    ngAfterViewInit() {
        
      if (!this.fit.img) {
        this.http.get(this.img.nativeElement.getAttribute('data-src'), {responseType: 'text'})
        .subscribe(
          (info) => {
            if (info == 'image not found') {
              this.img.nativeElement.setAttribute('src', '/assets/no_img_avaliable.jpg')
            } else {
              this.img.nativeElement.setAttribute('src', info)
              this.fit.img = info
            }
          },
          _ => {
            this.img.nativeElement.setAttribute('src', '/assets/no_img_avaliable.jpg')
          }
        );
      } else {
        this.img.nativeElement.setAttribute('src', this.fit.img)
      }
    }


    // called from html
    private onSaveComments() {
      let body:any ={};
      body.tag_id = this.fit.TagID
      body.comments = this.fit.comments
      this._fitsService.update(body, (ok) => {
        let selector: string = `#details-${this.fit.TagID} #inputComments`;
        let className: string = (ok ? 'save-result-success' : 'save-result-error');
        $(selector).addClass(className);
        setTimeout(() => {
            $(selector).removeClass(className);
        }, 750);
      });
    }

    private showSpinnerOnDownload(show: boolean) {
      let ctrl = $('#download-spinner');
      if (show) {
        ctrl.removeClass('d-none');
      } else {
        ctrl.addClass('d-none');
      }

    }

    // called from html
    private pushContentTar(): void {
      this.showSpinnerOnDownload(true);
      let filename = this.fit.TagID + '.tar.gz';

        this.http
          .get<any>(`${environment.api.fits}/${this.fit.TagID}?redirect=false`)
          .subscribe(
            resp => {
              //if tar
              if(resp.location != "Empty") {
                Utils.pushDownloadUrl(String(resp.location), filename)
                this.showSpinnerOnDownload(false);
              }
              //if metadata
              else {
                //download as METADATA
                this.http
                .get<any>(`${environment.api.metadata_tar_api}/${this.fit.TagID}?redirect=false`)
                .subscribe(
                  resp2 => {
                    if(resp2.location) {
                      Utils.pushDownloadUrl(String(resp2.location), filename);
                      this.showSpinnerOnDownload(false)
                    }
                    else {
                      console.log(resp2)
                      this.showSpinnerOnDownload(false)
                      alert(`It\'s not possible to download this measure: ${this.fit.code_measure}`)
                    }
                  },
                  err2 => {
                    console.log(err2)
                    this.showSpinnerOnDownload(false)
                    alert(`It\'s not possible to download this measure: ${this.fit.code_measure}`)
                  }
                )
              }
            },
            err => {
              console.log(err)
              this.showSpinnerOnDownload(false);
              alert(`It\'s not possible to download this measure: ${this.fit.code_measure}`)
            }
          );
    }

}