import {
    Component,
    Injector,
    ComponentFactoryResolver, ApplicationRef, EmbeddedViewRef,
    OnInit, AfterViewInit,
    Input, Output, Injectable,
    EventEmitter,
  } from '@angular/core';
  import * as $ from 'jquery';
  import 'datatables.net'
  import { BsModalService } from 'ngx-bootstrap/modal';
  import * as moment from 'moment';
  import { AuthService } from '../auth/auth.service';
  import { SettingsDetailsComponent} from './settings_details.component';
  import {ShopService} from '../services/shop.service'
  import { Shop } from '../services/shop';
  import { ThicknessService } from '../services/thickness.service';
  
  @Component({
    selector: 'app-settings-table',
    templateUrl: './settings_table.component.html',
    styleUrls: ['../orders/table.component.css']
  })
  export class TableSettingsComponent implements OnInit, AfterViewInit{
    @Input()
    private shops: any;
    public shopss: Shop[] = [];
  
    constructor(
      //private _fitsService: FitsService,
      private componentFactoryResolver: ComponentFactoryResolver,
      private injector: Injector,
      private appRef: ApplicationRef,
      private modalService: BsModalService,
      private auth: AuthService,
      private thickness: ThicknessService,
    ) { }
  
    private _table: DataTables.Api = null;  
    private dataLoaded = new EventEmitter<void>();
    ngOnInit() {
      this.shops = this.auth.shops_list().split("+")
      this.thickness.getThickness()

      // LOAD settings data from cloud
    }
  
    ngAfterViewInit() {
        setTimeout(() => {
          this._table = $('#settingsTable').DataTable({
            destroy: true,
            order: [[1, 'asc']],
          });
          //$('#loading').addClass('invisible');
          //$('#tableContainer').removeClass('invisible');
        }, 100);
  
    }

    private onRowSelect(shop: any) {
      let row = this._table.row('#row-' + shop);
      if (row.child.isShown()) {
        row.child(false);
        row.$('#row-' + shop).removeClass('shown');
      } else {
        /*console.log(shop['id'] )
        row.child(shop['id']).show();*/
  
        const componentRef = this.componentFactoryResolver
        .resolveComponentFactory(SettingsDetailsComponent)
        .create(this.injector);
  
        componentRef.instance.shop = shop;
      
        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);
      
        // Get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
          .rootNodes[0] as HTMLElement;
          
      
        domElem.id = "details-" + shop;
      
        row.child(domElem).show();
        row.$('#row-' + shop).addClass('shown');
      }
    }

  
  }
  