import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { XMLHttpRequest } from 'aws-sdk/lib/http_response';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';


@Injectable()
export class SettingsService {
  private headers: any = new HttpHeaders({
    'Content-Type': 'application/json',
  })

  constructor(
    private _http: HttpClient,
    private auth: AuthService
  ) {
  }

  public getConfig(shop_id: any) {
    let body:any ={};
    body.shop_id = shop_id
    return this._http.post(environment.api.settings + '/' + 'getConfig', body, { headers: this.headers })
  }

  public postConfig(shop_id: any, config_to_upload: any) {
    let body:any ={};
    body.shop_id = shop_id
    body.config = config_to_upload
    let headers = new HttpHeaders({
        'Content-Type': 'application/json',
    })
    return this._http.post(environment.api.settings + '/' + 'setConfig', body, { headers: this.headers })
  }

  public uploadVideo(shop_id: any, file: any, enabled: boolean): any{
    let body: any = {
      "shop_id": shop_id,
      "video_metadata": {
        "original_name": file.name,
        "encoding": file.type,
        "size": file.size,
        "enabled": enabled
      }
    }

    return this._http.post(environment.api.settings + '/uploadVideo', body, { headers: this.headers } )
  }
}
